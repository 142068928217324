@import "../../../styles/variables";

.right-bar {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: @color-white;
  box-shadow: 8px 4px 5px 0 #E4E5EB;

  .right-bar-filler { 
    border-bottom: 1px solid #dddfe6;
    background-color: @color-white;
    height: 64px;
    flex-shrink: 0;
    padding: 0 16px;
    justify-content: flex-end;
    align-items: center;
  }

  .hm-tabs { 
    position: relative;
    display: flex;
    flex: 1;
    border-left: 1px solid #dddfe6;
    overflow: hidden;

    flex-direction: column;

    .hm-tab-list {
      flex-shrink: 0;
    }

    .hm-tab-content {
      flex: 1;
      overflow: hidden;
    }
  }
}
