@import '../../../styles/variables.less';

.tag-item {
    .bubble {
        position: absolute;
        background: #ffffff;
        color: #000000;
        font-family: Arial;
        font-size: 13px;
        text-align: center;
        width: 155px;
        border-radius: 5px;
        padding: 5px;
        box-shadow: 0px 2px 4px #B7BCD0;
        z-index: 3;

        .tag-item-btn {
            background: none;
            border: none;
            width: 100%;
            user-select: none;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #272727;
            
        }

        .tag-item-btn:hover {
            background: #F0F3F7;
        }
    }

    .bubble:after {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        border-style: solid;
        border-color: #ffffff transparent;
        border-width: 0 8px 10px;
        top: -5px;
        left: 51%;
        margin-left: -8px;
    }

}

.tag-item-container {
    background-color: #CEDCFF;
    color: #4E6190;
    font-size: 12px;
    width: fit-content;
    display: flex;
    align-items: center;
    line-height: 0px;
    border-radius: 2px;
    margin-right: 5px;
    height: 20px;


    p {
        margin: 3px 10px 3px 3px;
        font-weight: 600;
    }

    svg {
        fill: #4E6190;
        width: 16px;
        height: 16px;
        border-radius: 3px;
        font-size: 10px;
        margin: 2px 4px;
    }

    svg:hover {
        background: #8c9ecd;
        cursor: pointer;
    }
}

.tag-item-container:hover {
    background-color: #9EB3E8;
}

.tag-list-container {
    display: flex;
    align-items: center;
}

#tag-item-horizontal-line {
    margin-top: 8px;
    margin-bottom: 6px;
    border-top: 1px solid #D5DCE3;
    width: 100%;
}