@import "../../../../styles/variables.less";

.marley-text {
    &.marley-text-primary {
        .primary-body-text()
    }
    &.marley-text-header-3 {
        .header-3()
    }
    &.marley-text-small-text {
        .small-text()
    }
}