@import '../../../styles/variables.less';

.chatActive {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  border-right: 1px solid #dddfe6;
  border-left: 1px solid #dddfe6;
}

.resultMessage {
  text-align: center;
  color: @font-color-shark;
  font-size: @font-size-biggest-rem;
  margin-bottom: 24px;
}
 