.auto-reply-send-cadence {
  width: 100%;
  text-align: right;
  p {
    font-size: 13px;
    line-height: 14px;
    text-align: right;
    background: white;
    border: none;
    color: #5e7388;
  }
}
