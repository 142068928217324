@import "../../../../styles/variables.less";

.marley-title {
    &.marley-title-subheader {
        .subheader-title()
    }
    &.marley-title-header-3 {
        .header-3()
    }
    &.marley-title-cardheader {
       color: #537388;
       font-size: 14px;
       font-weight: 600;
       line-height: 20px;
    }

}

