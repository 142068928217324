@import "./variables";

  .authenticated {
    display: flex;
    min-width: @authenticated-width;
    flex-grow: 1;
  }

  .hm-tabs {
    flex: 1;
  }

  .dashboard {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    position: relative;
    height: 100%;
    width: 100%;
    min-width: @min-app-width - @left-panel-width;
    overflow: auto;
  }
