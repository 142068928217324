@import "../../../../../styles/variables";

.has-event-message {
  color: @color-dark-gray;
  margin: 16px;
  text-align: center;
  line-height: 14px;
  font-size: @font-size-normal;
}

.has-event-message-mass-note {
  color: @color-dark-gray;
  margin-top: 0px;
  text-align: center;
  line-height: 14px;
  font-size: @font-size-normal;
}

.copart-event-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 15px;
  color: #5e7388;

  .left-side {
    display: flex;
    flex-direction: row;
  }

  .right-side {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
  }

  .partner-alert-icon {
    height: 32px;
    width: 32px;
    margin: 4px 4px 0 0;
  }

  .timestamp {
    padding-left: 4px;
    font-size: 10px;
  }

  .copart-release-issue-text {
    display: flex;
    padding: 4px;
    align-items: center;

    .text {
      font-size: 15px;
      font-weight: 600;
    }

    .timestamp {
      padding-left: 8px;
    }
  }

  .copart-release-issue-description {
    padding: 4px;
  }

  .copart-single-line {
    padding-top: 8px;
  }

  .template-link {
    font-size: 12px;
    padding: 8px 0 4px;
    margin-right: 5px;
    cursor: pointer;
  }
}

.partner-event-message-container {
  display: flex;
  flex-direction: row;
  padding: 0 15px;
  color: @grey;

  .partner-alert-icon {
    height: 32px;
    width: 32px;
    margin: 4px 4px 0 0;
  }

  .partner-event-message {
    display: flex;
    padding: 4px;
    align-items: center;

    .text {
      font-size: 15px;
      font-weight: 600;
    }

    .timestamp {
      padding-left: 8px;
      font-size: 10px;
    }
  }

}