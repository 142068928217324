@import '../../styles/variables.less';

.trayContainer {
  padding: 0 0 16px 16px;
  max-height: auto;
  overflow: auto;
}

.trayItemTitle {
  font-family: @font-family-standard;
  font-size: @font-size-medium;
  line-height: @font-size-bigger;
  color: @color-dark-gray;
}
.trayItemHeader {
  font-family: @font-family-standard;
  font-size: @font-size-bigger;
  color: @color-just-black;
}
.trayItemList {
  font-family: @font-family-standard;
  font-weight: @font-weight-medium;
  font-size: @font-size-medium;
  line-height: @font-size-bigger;
  color: @font-color-shark
}
.trayItem {
  margin-bottom: 20px;
}
.trayItemLink {
  font-family: @font-family-standard;
  font-weight: @font-weight-medium;
  font-size: @font-size-medium;
  line-height: @font-size-bigger;
  color: @color-blue-background;
  display: inline-block;
  vertical-align: middle;
  text-decoration: underline;
  svg {
    vertical-align: middle;
    margin-left: 5px;
  }
}
