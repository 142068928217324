@import '../../../styles/variables.less';

.divider {
    height: 16px;
    position: relative; 
    display: flex;
    justify-content: center;
    margin: 0px 10px;

    .divider-text {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;

        color: #5E7388;
        background-color: white;
        /* width: 100px; */
        justify-content: center;
        padding: 0 3px;
        z-index: 1;
    }
    .divider-line {
        transform: translateY(-50%);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: 1px solid #CFD7DF;
    }
}