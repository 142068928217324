@import "../../../../../styles/variables";

.message-body {
  .emoji {
    width: 16px;
    height: 16px;
  }
}

.unread-messages-separator {
  text-align: center;
  position: relative;
  scroll-margin-top: 10px;

  .text {
    width: 35px;
    position: absolute;
    left: calc(46% + 6px);
    top: -6px;
    background-color: @color-marley-red;
    border-radius: 8px;
    font-weight: 400;
    color: white;
    font-size: @font-size-small;
    z-index: 10;
  }

  .line {
    border-bottom: 1px solid @color-marley-red;
  }
}