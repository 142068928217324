@import "./variables";

.chat-header {
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.chat-title {
  color: @font-color-black;
  font-size: @font-size-biggest;
  font-weight: @font-weight-medium;
}

.chat-log {
  overflow-y: scroll;
  flex: 1 1;
  background-color: #fff;
  padding-top: 20px;

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
}

/* Job tabs */

.active-jobs {
  flex: 2;
  z-index: 2;
  flex-direction: column;
  display: flex;

  .nav-tabs {
    border-bottom: 0;
    padding: 0 20px;
    height: 55px;
    overflow: hidden;
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .nav-tabs>li {
    font-size: @font-size-big;
    font-weight: @font-weight-bold;
    height: 38px;
    margin-top: 11px;
    box-shadow: 0 -3px 10px 0 #333;
    margin-right: 10px;
    background-color: @color-white;
    opacity: .7;
    display: flex;
    flex: 1 0 0px;
    max-width: 50%;
  }

  .nav-tabs>li.active {
    opacity: 1;
  }

  .nav-tabs>li.active a,
  .nav-tabs>li.active a:hover {
    border: none;
  }

  .nav-tabs>li a {
    color: @font-color-black !important;
    margin-right: 0;
    display: flex;
    flex-direction: row;
    width: 100%;

    span:not(.glyphicon) {
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
      display: block;
      flex: 1 0 auto;
      text-align: left;
    }

    span.glyphicon {
      padding-right: 3px;
    }
  }
}

.active-job-selected {
  position: relative;
  height: 100%;
  box-shadow: 0 -3px 10px 0 #333;
  z-index: -1;
  background-color: white;
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

/* Chat */

.chat-tab {
  // flex-grow: 1;
  width: 100%;
  position: absolute;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row
}

.chat-window {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1 0 0px;
  max-width: 50%;
}


.chat-header {
  margin-bottom: 20px;
}

.message-form {
  // display: flex;
  // flex-direction: row;
}

.message-button-col {
  display: flex;
  flex-direction: column;
}

.message-button-col input {
  margin-bottom: 10px;
}


.btn-marley-chat {
  background-color: @color-charcoal-grey;
  color: white;
  text-transform: uppercase;
  font-size: @font-size-small;
  border: 0;
  border-radius: 5px;
  margin-right: 10px;
  position: relative;
}

.message-button,
.register-user-button {
  background-color: @color-charcoal-grey;
  color: white;
  text-transform: uppercase;
  font-size: @font-size-small;
  border: 0;
  border-radius: 5px;
  /* width: 66px; */
  height: 36px;
}

/* Queue Table */

.queue-table {
  padding: 0 20px;
}

/* .queue-table {
  padding: 0 20px;
  display: flex;
  flex: 1 0 auto;
}

.queue-table .table-responsive {
  position: relative;
  width: 100%;
  border: 1px solid transparent;
} */

.queue-table-unobscured {
  display: flex;
  flex-direction: column;
}

.queue-table-obscured {
  filter: opacity(.5) blur(1px);
  overflow: hidden;
}

.queue-table-overlay {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  color: transparent;
}

.queue-table-overlay:before {
  content: "";
  background-color: black;
  opacity: .7;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

/*
.table>thead>tr.queue-header>th {
  border-bottom: 0;
  padding-left: 0px !important;
}

.table>tbody>tr.queue-row>td {
  border-top: 0;
  vertical-align: middle;
  padding: 0;

  &.job-id {
    padding-left: 10px;
  }

  &.lead-col-open, &.lead-col-closed {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 0;
    border-right:none;
    background-color: transparent;
  }

  &.lead-col-open {
    background-color: @color-green;
  }

  &.lead-col-closed {
    background-color: @color-light-grey;
  }

} */


.table>tbody>tr.queue-row-operatorchat>td {
  background-color: @color-white !important;
  border-top: #eeeeee 1px solid !important;
  border-bottom: #eeeeee 1px solid !important;

  &.lead-col-open {
    background-color: @color-blue !important;
  }
}

/* .table>tbody>tr.queue-row-open>td {
  background-color: @color-lighter-blue;
}
.table>tbody>tr.queue-row-closed>td {
  background-color: @color-lighter-grey;
  font-weight: normal;
  color: @font-color-grey-three;
} */

.queue-row-closed .deadline {
  font-size: @font-size-small
}

tr.queue-row td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: none;
}

.is-open svg {
  margin-top: 4px;
}

.ai-action {
  text-align: center;

  svg {
    fill: @font-color-grey-three
  }
}

.job-id {
  color: @color-black;
  font-size: @font-size-normal;
  font-weight: @font-weight-medium;
  padding: 11px 5px 11px 6px;
  margin-left: -6px;
  cursor: pointer;
}

.new-message div {
  display: flex;
  align-content: center;
}

.queue-row-closed {
  .job_id {
    color: @color-medium-grey
  }

  .btn-group>.btn-default {
    border: 1px solid @color-light-grey;
    color: @font-color-grey-three;
  }
}

.sort-column {
  cursor: pointer;
}

.sort-column:hover {
  color: @font-color-black;
}

.table>tbody>tr.queue-row>td.lead-col {}

.queue-header>th {
  color: @font-color-grey-three;
  font-size: @font-size-small;
  font-weight: @font-weight-medium;
}

.customer-name,
.latest-activity {
  color: @font-color-grey-two;
  font-size: @font-size-small;
}

.customer-name div,
.ai-action div,
.latest-activity div,
.deadline div {
  height: 40px;
  align-items: center;
  display: flex;
  padding: 0 5px;
  cursor: pointer;
}

.deadline {
  font-size: @font-size-big;
  font-weight: @font-weight-bold;
}

th.deadline {
  padding: 0px 10px;
}

.deadline-green,
.deadline-yellow,
.deadline-red {}

.deadline-green {
  color: green;
}

.deadline-yellow {
  color: yellow;
}

.deadline-red {
  color: @color-red;
}

.deadline svg {
  height: 25px;
  width: 25px;
}

.btn-group>.btn-default {
  background-color: inherit;
  font-size: @font-size-normal;
  border: 2px solid @color-medium-grey;
}