@import "../../styles/variables";

.inactivity-modal {

  .modal-content {
    width: 70%;
    text-align: center;
    border-radius: 8px;

    .inactivity-title, .inactivity-descr {
      padding: 10px 0;
    }
    .countdown{
      font-weight: 600;
      padding: 0;
    }
  }
}
