@import "../../styles/variables.less";

.chatsComponent {
  display: flex;
  height: 100%;
  width: 100%;
  background: @color-white;
  .chatsList {
    width: 25%;
    z-index: 4;
  }
  .chatComponent {
    width: 45%;
  }
  .right-bar {
    width: 30%;
  }
}

@media only screen and (max-width: @mobile-width) {
  .chatComponent {
    width: 100% !important;
    border: none;
  }

  .chatsComponent {
    .right-bar {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1000;
      display: none;

      &.visible {
        display: block;
      }

      .right-bar-filler {
        justify-content: space-between;
      }
    }
  }
}

