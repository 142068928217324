@import "../../../../styles/variables";

.text-input, .text-input-line {
  margin-bottom: 16px;
  
  .text-input-box {
    .input-area();
    &.error {
      border-bottom: 1px solid @color-red-error;
    }
  }

  .text-input-help {
    .secondary-body-text();
    color: @color-black-38;
    margin: 8px 0px 16px;
  }
}

.text-input-line {
  .text-input-box {
    .input-line();
  }
}