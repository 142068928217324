@import '../../../../styles/variables.less';

@color-chat-participant-add-button-border: @color-dark-gray;
@color-chat-participant-add-button-border-hover: @color-marley-blue;
@color-chat-participant-add-button-text: @color-dark-gray;
@color-chat-participant-add-button-text-hover: @color-marley-blue;

.chat-participant-add-button {
  &:hover {
    color: @color-chat-participant-add-button-text-hover;
    background-color: transparent !important;

    .chat-participant-add-circle {
      border-color: @color-chat-participant-add-button-border-hover;
      border-style: solid;
    }
  }
}

.chat-participant-add-circle {
  border: 2px dashed @color-chat-participant-add-button-border;
  border-radius: 100%;
  display: block;
  font-size: 24px;
  width: 32px;
  height: 32px;
}