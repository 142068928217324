@import '../../../styles/variables.less';

:global(.DropdownMenuCheckboxItem[data-state='checked']),
:global(.DropdownMenuSubTrigger[data-state='open']) {
  font-weight: 500 !important;
}

.chatInbox {
  width: 25%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  background-color: white;

  .inboxContentAndFooter {
    overflow: hidden;

    .inboxCard {
      position: relative;
      margin: 20px;

      .pinIcon {
        position: absolute;
        bottom: 10px;
        right: 10px;
      }
    }
  }
  min-width: 320px;
}

@media only screen and (max-width: @mobile-width) {
  .chatInbox {
    width: 100%;
  }
}

.error {
  background-color: @color-athens-gray;
}

.rightBarWrap {
  border-right: 1px solid @color-mischka;
  border-left: 1px solid @color-mischka;
}

.errorWrap,
.rightBarWrap {
  color: @color-dark-gray;
  font-size: @font-size-normal-rem;

  p {
    font-size: @font-size-bigger-rem;
  }
}

.errorWrap,
.activeWrap,
.rightBarWrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
  }
  span {
    color: @color-tuna;
  }
}

.activeWrap {
  width: 45%;
  font-weight: 400;
  border-right: 1px solid @color-mischka;
  border-left: 1px solid @color-mischka;
  p {
    font-size: @font-size-biggest-rem;
  }
}

