@import "../../styles/variables.less";

// DIV that container the login background

#okta-login-container {
  background-image: url("../../../images/backgrounds/login-background.inbundle.svg");
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center center;
}

// Okta Login
#okta-sign-in {
  width: 390px;
  height: 630px;
  background: #ffffff;
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  border: none;
}

#okta-sign-in .auth-header {
  border: none;
}

#okta-sign-in.auth-container .okta-sign-in-header {
  border-bottom-color: #ddd;
  padding-top: 45px;
}

// Okta Login Title
#okta-sign-in.auth-container h2,
#okta-sign-in.auth-container h3 {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.6);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}

.auth-footer {
  text-align: center;
}

#idp-discovery-username::-webkit-input-placeholder::before {
  color: #666;
  content: "Line ";
}

// Okta Login Sign in Btn
#okta-sign-in.auth-container .button-primary {
  background-color: #ae3d36 !important;
  border: none !important;
  border-radius: 32px;
  max-width: 326px;
  max-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: @mobile-width) {
  #okta-login-container {
    background-image: none;
  }
}
