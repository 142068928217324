@import '../../../styles/variables.less';

.personal-welcome-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding-left: 27px;
  margin: 0;

  .personal-welcome-message {	
    color: #16212E;	
    font-size: 47px;	
    text-align: left;
    .personal-welcome-message-bold {
      display: inline-block;
      font-weight: 600;
    }
  }
  
  .personal-secondary-message {
    padding: 18px 0 10px 0;
    color: #16212E;
    font-size: 20px;
    letter-spacing: -0.5px;
  }
}


@media screen and ( max-width: 1526px ) {
  .personal-welcome-container { 
    .personal-welcome-message {	
      font-size: 42px;	
    }
    .personal-secondary-message {
      font-size: 17px;
    }
  }
}