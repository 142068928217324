.search-dropdown { 
    .search-dropdown-link {
        width: 100%
    }

    .marley-dropdown-caret {
        position: absolute;
        right: 10px;
        z-index: 5;    
    }
}