@import '../../../../styles/variables.less';

.view-participant-item {
  position: relative;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  border-top: 1px solid @color-gray-background;
  border-bottom: 1px solid @color-gray-background;
  width: 100%;
  padding: 20px 0;

  + .view-participant-item {
    border-top: 0;
  }

  @media only screen and (max-width: @mobile-width) {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 0;

    .view-participant-item-profile,
    .view-participant-phone-number,
    .view-participant-item-status {
      width: 100%;
      padding: 0 0 4px;
    }

    .user-profile {
      flex-direction: column;
    }

    .user-profile-info {
      margin: 4px 0 0 0;
    }
  }
}

.view-participant-item-removed {
  background: @color-catskill-white;
  color: @color-dark-gray;
}

.view-participant-item-profile {
  flex: 1;
}

.view-participant-phone-number {
  display: flex;
  flex-direction: column;
  width: 25%;
}

.view-participant-item-opt-in-status {
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.opted-in-status {
  color: @color-caribbean-green;
}

.not-opted-in-status {
  color: @color-dark-gray;
}

.view-participant-item-status {
  width: 25%;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.reinvite-participant-button {
  text-decoration: underline;
  text-underline-offset: 2px;
  color: @color-black;
  background: none;
  border: none;
}

.view-participant-item-actions {
  width: 200px;
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: @mobile-width) {
    position: absolute;
    top: 16px;
    right: 0;
  }

}

.view-participant-item-opt-in-status-removed {
  color: @color-dark-gray;
}

svg.opt-in-icon-removed {
    fill: @color-dark-gray;
}

svg.opt-in-icon-normal {
  fill: @color-caribbean-green;
}