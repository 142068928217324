@import '../../../../styles/variables.less';
.dynamicLabel {
  display: flex;
  align-items: center;
  color: @color-fiord;
  justify-content: center;
  font-size: @font-size-medium-rem;
  svg {
    width: 16px;
    height: 16px;

    path {
      fill: @grey;
    }
  }
}
