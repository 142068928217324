@import "../../../../styles/variables";

.customer-feedback-item {
    border-radius: 3px;
    background-color: @color-white;
    box-shadow: 0 0 4px 0 @color-gray;
    display: flex;
    flex-direction: column;
    padding: 7px 11px;
    margin: 5px;
    font-size: 14px;
    line-height: 15px;
    position: relative;

    .customer-feedback-item-rate-wrap {
        position: absolute;
        right: 3px;
        width: 70px;

        .customer-feedback-item-timestamp {
            height: 13px;
            width: 15px;
            color: #000000;
            font-size: 10px;
            line-height: 13px;
            word-wrap: normal;
        }

        .customer-feedback-item-rating {
            display: flex;
        }

        .customer-feedback-item-rate-stars {
            color: #FFE92A;
            overflow: hidden;
            display: flex;
        }

        .customer-feedback-item-rate-num {
            font-family: @font-family-table;
            font-size: 12px;
            line-height: 13px;
            margin-right: 5px;
        }
    }

    .customer-feedback-user {
        color: @color-dark-gray;
        font-family: @font-family-table;
        font-size: 14px;
        line-height: 16px;

        .customer-feedback-user-value {
            color: @color-black;
            font-size: 14px;
            line-height: 14px;
        }
    }

    .customer-feedback-review {
        margin-top: 6px;
        color: @color-dark-gray;
        font-family: @font-family-table;
        font-size: 14px;
        line-height: 16px;

        .customer-feedback-review-value {
            color: @color-black;
            font-size: 14px;
            line-height: 18px;
        }
    }
}