.settings-checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .settings-checkbox-container-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .infoSvg{
    width: 22px;  
    height: 22px;
  }

  input[type='checkbox'] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #5e7388;
    border-radius: 2px;
    width: 18px;
    height: 18px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  input[type='checkbox']:checked:after {
    content: '✓';
    color: white;
  }
  input[type='checkbox']:checked {
    background: #2e88ff;
    border: #2e88ff;
    
  }
  .admin-required {
    input[type='checkbox']:checked:after {
      content: '✓';
      color: rgb(46, 46, 46);
      font-size: 30px;
    }
  }
}
