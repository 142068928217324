@import "../../../../../styles/variables.less";

.auto-reply-message-container {
  background: #f5f5f5;
  border-radius: 5px;
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
  display: flex;
  align-items: center;
  padding: 2%;
  .auto-reply-message-body {
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #5e7388;
  }
}
