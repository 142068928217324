@import '../../../../../styles/variables.less';

.error-row,
.copy-list-link {
  text-align: center;
  color: #de2c42;
  padding-bottom: 8px;
  height: 27px;
}

.nameDescriptionWrap {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;

  > div {
    width: 50%;
    margin-right: 30px;
  }
}

a.copy-list-link {
  font-weight: 600;
  cursor: pointer;

  &:hover {
    color: #de2c42;
    text-decoration: underline !important;
  }
}

.create-group-modal {
  input[type='file'] {
    display: none;
  }

  .char-count {
    font-size: 14px;
    color: #cfd7df;
    text-align: right;
    padding-top: 8px;
  }

  .search-bar {
    margin: 8px;
  }

  .add-members {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-end;
  }

  .list-column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    width: 51%;

    &:first-child {
      padding: 12px 8px 12px 0;
    }

    &:last-child {
      padding: 12px 0 12px 8px;
    }

    .scroller {
      overflow: scroll;
      height: 100%;
    }
  }

  .sub-subheader {
    font-weight: 600;
    color: #16212e;
    height: 16px;
    display: flex;
    justify-content: space-between;
  }

  .sub-subheaders {
    font-weight: 600;
    color: #fb6758;
    height: 16px;
    display: flex;
    justify-content: flex-start;
  }

  .add-multiple-users-link {
    color: var(--chakra-colors-blue-500);
    cursor: pointer;
    font-weight: normal;
    border: none;
    background-color: transparent;
  }

  .sub-subheader-label {
    .secondary-body-text();
    margin-right: inherit;
  }

  .membersAndLeadsWrap {
    width: 100%;
    height: 100%;

    .noMembersAndLeadsText {
      .secondary-body-text();
      color: @color-black-38;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      align-items: center;
    }
  }

  .column-group {
    border: 1px solid #cfd7df;
    height: 300px;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }

  .item {
    padding: 4px 16px;
    margin: 4px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    min-height: 46px;

    .item-column {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 75%;

      .role {
        color: @color-dark-gray;
        text-transform: capitalize;
      }

      .role-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .lead-icon {
          padding-left: 4px;

          svg {
            height: 14px;

            path {
              fill: @color-dark-gray;
            }
          }
        }
      }
    }

    .button-column {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 25%;
      margin-right: auto;

      .hymarley-icon-button:hover {
        path {
          fill: @color-marley-blue;
        }
      }

      .add-icon {
        padding-right: 2px;
        padding-left: 0;
      }

      .lead-icon {
        padding-right: 2px;
      }

      .remove-icon {
        margin-left: auto;
      }
    }
  }

  .item:hover {
    background-color: #f0f3f7;
    cursor: pointer;
  }

  .item .button-column {
    visibility: hidden;
  }

  .item:hover .button-column {
    visibility: visible;
  }

  .file-drop-target-group {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 600;
    font-size: 16px;
    display: none;
  }

  .file-drop-dragging-over-frame {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    z-index: 1000;
    opacity: 0.9;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    justify-content: center;
    align-items: center;
    .file-drop-target-group {
      display: flex;
    }
  }

  .userList-container {
    height: 100%;
    overflow-y: auto;
    position: relative;
  }
}
