@import '../../../styles/variables.less';

.last-edited{
    display: flex;
    padding-right:10px;
    align-items: center;
    
  #last-edited {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    padding-right: 5px;
  }
  #first-last-name {
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
  }
  .last-edited-svg {
    width: 20px;
    height: 20px;
    margin: 0 10px;
  }
  #last-edited-time {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    margin: 0 5px;
  }
}
