.media-message-container {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
  width: 250px;
}

.media-message-container-toolbar {
  margin-top: 10px;
  flex: 0 0 auto;
  position: absolute;
  right: 10px;
}
