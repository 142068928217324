@import '../../../styles/variables.less';

.user-notifications-settings-container{
    padding: 32px;

    .button-container{
      button:focus{
        border-radius: 16px;
      }
    }
    .user-notifications-settings-title{
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        color: #16212E;
        margin-bottom: 32px;
    }
    
    .user-notifications-settings-intro-container {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      color: #16212e;
      display: flex;
    }
    .user-notifications-default-container {
      text-align: right;
    }
    .user-notifications-settings-restore-btn {
      background: none;
      text-decoration: underline;
      border: none;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      text-align: right;
      text-decoration-line: underline;
      color: #5e7388;
    }
}



