.settings-table-wrapper {
    input[type="radio"]{ 
        width: 18px;
        height: 18px;
    }
  
    .settings-table-intro h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      /* identical to box height */
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
  
      /* System Styles/High Emphasis */
      color: rgba(0, 0, 0, 0.87);
    }
  
    .settings-table-intro p {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.6);
    }
  
    .settings-table-table {
      width: 100%;

      h3 {
        font-size: 16px;
        margin: 0 0 8px;
      }

      p {
        margin: 0;
      }
    }

    .settings-table-Input-title {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.6);
      white-space: nowrap;
      text-transform: capitalize;
    }
    th,
    td {
      width: 100%;
      padding: 16px;
      height: 56px;

      div {
        display: inline;
      }

      input:hover {
        cursor: pointer;
      }
    }

    tr:nth-child(even) {
      background: #2121210a;
    }
  }
  