@import "../../../../../styles/variables.less";

.create-auto-reply-modal {
  .enter-form-title {
    padding-bottom: 0px;
    font-size: 16px;
    font-weight: 600;
  }

  .form-field {
    padding: 8px 0 0 0;

    .form-group {
      margin: 0px 12px;
    }

    .form-field-input {
      width: 60%;
    }
  }

  .auto-reply-title {
    height: 32px;
    width: 100%;
    border: 1px solid #cfd7df;
    padding: 4px;
    resize: none;

    label {
      display: none;
    }
  }

  .auto-reply-message-textarea {
    height: 114px;
    width: 100%;
    border: 1px solid #cfd7df;
    padding: 4px;

    label {
      display: none;
    }
  }

  textarea::placeholder {
    color: #cfd7df;
  }

  .button-row {
    margin-top: 5%;
    display: flex;
    justify-content: flex-end;

    .cancel {
      margin-right: 8px;
      border: 1px solid @color-just-black;
      color: @color-just-black;
    }

    .cancel:hover {
      background: @color-just-black;
      color: white;
    }

    .create-auto-reply-button {
      border: 1px solid @color-ui-green;
      background: @color-ui-green;
      color: white;
    }
  }

  .auto-reply-explanation {
    p {
      font-weight: normal;
      font-size: 15px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #1f3042;
    }
  }

  .auto-reply-check-block {
    color: @color-dark-gray;
    font-size: 13px;
    display: flex;
    align-items: center;
    margin-top: 3%;

    .auto-reply-checkbox {
      transform: scale(1.5);
      width: 11px;
      height: 11px;
      cursor: pointer;
      font-size: 9px;
      position: relative;
      margin-right: 10px;

      &:after {
        content: " ";
        background-color: #fff;
        display: block;
        color: @color-dark-gray;
        width: 11px;
        height: 11px;
        visibility: visible;
        border: 1px solid @color-dark-gray;
        border-radius: 3px;
      }

      &:checked:after {
        content: "\2714";
        font-weight: 600;
      }

      &:checked+label {
        font-weight: 600;
        font-size: 1.35rem;
      }
    }

    .specific-times {
      margin-left: 5%;
    }

    label {
      margin-top: 8px;
      font-weight: normal;
    }
  }

  .timezone-container {
    margin-top: 10px;

    h6 {
      font-size: 12px;
      line-height: 25px;
      margin-bottom: 2px;
      color: #16212e;
    }

    .timezone-dropdown {
      border: 1px solid #1e3043;
      box-sizing: border-box;
      border-radius: 3px;
      width: 225px;
      padding-left: 10px;
    }
  }
}