@import "../../../../../styles/variables.less";

.days-row {
  color: @color-dark-gray;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5%;
  .weekday-checkblock {
    margin-right: 4%;
    label {
      font-size: 12px;
      vertical-align: middle;
    }
  }
  .weekday-checkbox {
    flex-direction: row;
    transform: scale(1.5);
    width: 11px;
    height: 11px;
    cursor: pointer;
    font-size: 9px;
    position: relative;
    margin-right: 6px;

    &:after {
      content: " ";
      background-color: #fff;
      display: block;
      color: @color-dark-gray;
      width: 11px;
      height: 11px;
      visibility: visible;
      border: 1px solid @color-dark-gray;
      border-radius: 3px;
    }
    &:checked:after {
      content: "\2714";
      font-weight: 600;
    }
    &:checked + label {
      font-weight: 600;
      font-size: 1.35rem;
    }
  }
}