@import '../../../../../src/styles/variables.less';

.marley-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    box-sizing: border-box;
    border-radius: 16px;
    min-width: 144px;
    height: 40px;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;

    color: @color-white;

    svg {
        width: 24px;
        height: 24px;
    }

    &:hover {
        border: 1px solid @color-marley-blue;
    }
}

.marley-button-corners-round {
    border-radius: 16px;
}

.marley-button-corners-sharp {
    border-radius: 3px;
}

.marley-button&.marley-button-multi {
    justify-content: space-between;
}

.marley-button {
    justify-content: center;
}

.marley-button-positive-marley {
    background: @color-marley-red;
    border: 1px solid @color-marley-red;

    &:active {
        background: #E95F54;
        border: 1px solid #E95F54;
    }

    &:disabled {
        background: #F0F3F7;
        border: 1px solid #F0F3F7;
    }
}

.marley-button-disabled {
    background-color: @color-gray;
    border: 1px solid @color-gray;
    cursor: not-allowed;

    &:hover {
        border: 1px solid @color-gray;
    }
}

.marley-button-outline {
    border: 1px solid @color-input-focus;
    background: @color-white;
    color: @color-input-focus;

    &:active {
        background: @color-input-focus;
        color: @color-white;
    }
}

.marley-button-positive {
    background: @color-ui-green;
    border: 1px solid @color-ui-green;

    &:active {
        background: #3ACCAA;
    }

    &:disabled {
        background: #F0F3F7;
        border: 1px solid #F0F3F7;
    }
}

.marley-button-neutral {
    background: @color-input-focus;
    border: 1px solid #16212E;
    
    &:active {
        background: #16212E;
    }
}

.marley-button-redaction {
    background: @color-dark-gray;
    border: 1px solid @color-dark-gray;
    height: 27px;
    padding: 0 20px 5px;
}
