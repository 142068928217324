@import '../../../styles/variables';

.alert {
  display: flex;
  background-color: @color-seashell-peach;
  border: @color-brand-tangerine 1px solid;
  border-radius: var(--Radius-sm, 4px);
  padding: var(--spacing-15, 0.75rem) 1rem;
  position: relative;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  a {
    color: inherit;
  }
  a:hover {
    cursor: pointer;
  }
  .alert-icon {
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: @color-brand-tangerine;
      }
      margin-right: 10px;
    }
  }
  strong {
    font-weight: @font-weight-bold;
  }
  .alert-content {
    min-width: 70%;
    .alert-title {
      margin-bottom: 15px;
      font-size: @font-size-normal-rem;
      font-weight: @font-weight-medium;
    }
    .alert-message {
      font-size: @font-size-medium-rem;
      line-height: 25px;
      a {
        text-decoration: underline;
      }
      svg {
        position: relative;
        top: 4px;
      }
    }
  }
  .alert-link {
    font-weight: @font-weight-regular;
    min-width: 100px;
  }
  .alert-close {
    position: absolute;
    right: 20px;
    path {
      fill: @color-dark-gray;
    }
  }
}