@import '../../../../styles/variables';

.previewWrap {
  width: 100%;
  word-break: break-word;

  strong {
    font-weight: 600;
  }
}

.sentText {
  background-color: white;
  width: fit-content;
  height: fit-content;
  margin-left: 20px;
  margin-top: 20px;
  border-radius: 16px 16px 16px 0px;
  padding: 10px;
  margin-right: 10px;
}

.receivedTextWrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.receivedText {
  background: #1b73e7;
  border-radius: 16px 16px 0px 16px;
  width: fit-content;
  height: fit-content;
  margin-top: 10px;
  padding: 10px;
  margin-right: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.previewLabel {
  color: $color-dark-gray;
  margin-bottom: 10px;
}

.previewMessagesBox {
  border: 1px solid $color-border-light-gray;
  background-color: $color-background-gray;
  padding-bottom: 30px;
  white-space: pre-wrap;
  height: 80%;
  overflow: auto;
}

.loadingNotification {
  height: inherit;
  width: 100%;
  background-color: white;
  border: none;
  border-radius: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
  flex-direction: column;
  color: $color-gray;
}