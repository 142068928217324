@import '../../../../styles/variables.less';

.look-card-no-click {
  cursor: default !important;
}
.look-card {
  height: 80%; 
  width: 100%; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .look-quote-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    cursor: default;
    .quote-look-title {
      font-weight: 600;
      font-size: 20px;
      position: absolute;
      padding-bottom: 160px;
    }
  }
  .look-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    .look-title {
      height: 30%;
      font-weight: 600;
      font-size: 20px;
    }
    .look-result {
      font-size: 48px;
      padding-bottom: 20px;
      font-weight: 600;
    }
  }
}

