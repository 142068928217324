.bulk-reassign-modal {
    .message {
        display: flex;
        padding-bottom: 20px;
        color: #5E7388;
        display: flex;
        align-items: center;

        .message-logo {
            margin-right: 9px;
            height: 32px;

            svg {
                width: 32px;
                height: 32px;
            }
        }
    }
    .assign-user-dropdown-button {
        width: 173px;
    }
    .assign-user-dropdown.bulk-reassign-dropdown {
        padding-bottom: 16px;
    }
}