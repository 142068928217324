@import '../../../styles/variables';

.messageTray {
  width: 29%;
  > section {
    overflow-y: hidden;
    height: 100vh !important;
  }
  :global(.hm-tab-content), :global(.hm-tabs) {
    height: 95%;
  }
  .searchbarMessageTemplates {
    width: 100%;
    margin-top: 15px;
  }
}

.messageList {
  height: 95%;
}
.templateSectionTitle {
  margin: 15px 0px 15px 0px;
  color: @grey;
  font-size: 14px;
}

.messageTemplateWrap {
  margin-top: 20px;
  height: 100%;
}

.pinnedHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0px;

  svg {
    padding-right: 5px;
  }
}
.chat-input-token-popup-listitem {
  display: block;
}

.pinnedMsg,
.unpinnedMsg {
  cursor: pointer;
  position: relative;
  padding: 8px 0px 8px 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 95%;

  .template-text {
    display: flex;
    flex-direction: column;

    .wrap-text();
  }

  .templatePinWrapper {
    width: 20px;
    flex: 0;
  }

  &.active-nav-template {
    background-color: @color-catskill-white;
    border-radius: 8px;
  }

  div {
    flex: 1;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.pinnedMsg .icon-button,
.unpinnedMsg:hover .icon-button {
  display: flex;
}

.templateCard {
  margin-bottom: 8px
}

.ghostRows {
  margin-top: 10px;
}

.unpinnedMsg .icon-button {
  display: none;
}
.templateList::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.messagesTray {
  top: 0 !important;
  height: 100vh !important;
  :global(.divider) {
    display: none !important;
  }
}

.templateList {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  word-break: break-all;
  overflow-y: scroll;
  height: 85%;
  overflow-x: visible;
}

.mobileMessageTray {
  .templateList {
    height: 89% !important;
  }
}

@media only screen and (max-width: @mobile-width) {
  .messagesTray {
    width: 100vw !important;
  }
}
