@import "../../../styles/variables";

.img-display {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  &.img-display-thumbnail {
    object-fit: cover;
  }
  &.img-display-expanded {
    object-fit: contain;
    background-color: black;
  }
}