@user-media-metadata-width: 296px;

.media-metadata-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  min-width: @user-media-metadata-width;
  overflow-x: hidden;
  padding: 16px;
  background: rgba(255, 255, 255, 0.8);
}

.media-metadata-header {
  display: flex;
  align-items: center;
  min-width: 260px;
  margin-left: 16px;
}

.media-metadata-title {
  font-size: 24px;
  margin-left: 8px;
}

.media-metadata-info-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 16px;
  width: 100%;
  height: 100%;
}
