html,
body,
#root {
  height: 100%;
}

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  font-size: 16px;
  color-scheme: only light !important;
}

@-ms-viewport {
  width: device-width;
}

.ie-warning {
  text-align: center;
  font-size: 20px;
}

.root {
  height: 100%;
  position: relative;
  .security {
    height: 100%;
  }
}

.app-container {
  height: 100%;
  min-width: @min-app-width;
  display: flex;
  flex-direction: column;
  animation: fadeIn 1s normal forwards ease-in;
  animation-iteration-count: 1;
}

.center-box {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 1;
  overflow: auto;
}

.splashScreen {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.splashScreen .logo-container {
  height: 100px;
  width: 200px;
}

.splashScreen .logo-container svg {
  height: 100%;
  width: 100%;
}

.splashScreen .connectingText {
  font-size: 26px;
  margin-top: 0px;
  margin-bottom: 14px;
  color: #5e7388;
}

.splashScreen .connectingText.error {
  color: red;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .home-button {
    margin-top: 20px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

._pendo-badge {
  box-shadow: none !important;
  z-index: -9999999 !important;
  inset: revert !important;
  * {
    display: none !important;
  }
}

@media only screen and (max-width: @mobile-width) {
  ._pendo-badge {
    display: none !important;
  }
}

#pendo-resource-center-container {
  bottom: 10px !important;
}
