@import '../../../../../styles/variables.less';
.modal {
  background-color: @color-athens-gray;
  padding-top: 25px;
  padding-bottom: 25px;
  div {
    margin-top: 0;
    line-height: 1.7rem;
  }
}
