@import '../../../../../styles/variables.less';
.tag {
  border-radius: 16px;
  padding: 5px 14px;
  margin-right: 8px;
  margin-bottom: 12px;
  border: 1px solid transparent;
  font-size: @font-size-medium-rem;
}

.selected {
  border: 1px solid @color-tuna;
  color: @font-color-shark;
  font-weight: 600;
  background-color: white;
}

.unselected {
  border: 1px solid @color-misty-blue;
  color: @color-fiord;
  background-color: @color-athens-gray;
}

.disabled {
  border-color: @color-misty-blue;
  background-color: @color-athens-gray;
  color: @color-misty-blue;
}