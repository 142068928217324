@import '../../../../styles/variables.less';

.chat-module {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  max-height: 95%;
  position: relative;
  background-color: @color-white;
}

.locked-chat-input-wrap {
  background: #F0F3F7;
  box-sizing: border-box;
  height: 30%;
  border: 1px solid #CFD7DF;
  border-radius: 4px;
  margin: 8px 8px 15px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  p {
    margin-bottom: 20px;
    max-width: 90%;
  }
  button {
    cursor: pointer;
    background: @color-white;
  }
}