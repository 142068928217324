.learnMoreBtn {
  display: flex;
  flex-direction: row-reverse;

  &.hm-button-multi {
    justify-content: center;
  }

  svg {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }
}

.app-integration {
  height: 130px;

  .hm-row {
    align-items: center;
    padding: 0px 16px;
    .logo-img {
      width: 160px;
      align-items: center;
      display: flex;
      flex-grow: 0 !important;
      svg {
        width: 160px;
      }
    }
  }
}
