.personal-on-click-container {
  height: 315px;	
  width: 315px;
}

.personal-card-container {
  height: 100%; 
  width: 100%; 
  background-color: #FFFFFF;
  border-radius: 17px;	
  box-shadow: 0 0 4px 0 #B7BCD0;
  padding: 15px 15px 15px 15px; 
  align-items: center;
  text-align: center;
  &:hover {
    box-shadow: 0 0 20px 0 #B7BCD0;
  }
  button {
    margin: 0 auto;
  }
}

@media screen and ( max-width: 1526px ) {
  .personal-on-click-container {
    height: 280px;	
    width: 280px;
  }
}

