@import "../../../styles/variables.less";
@import "../../../styles/animation.less";

.list {
    overflow-y: auto;

    .list-item {
        width: 100%;
        flex: 1;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        height: @list-item-height;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 8px;
        margin: @list-item-margin-top 0px @list-item-margin-bottom;
        z-index: 101;
        cursor: pointer;

        &.list-item-close {
            color: @color-ui-red;
            svg {
                fill: @color-ui-red;
            }
        }

        .list-item-label {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: flex;

            .list-item-identifier-icon {
              margin-right: 4px;
              svg {
                width: 20px;
                height: 20px;
              }
            }
        }

        .list-item-check {
            display: none;
        }
        svg {
            width: 20px;
        }

        &:hover {
            background-color: #F0F3F7;
        }

        &.list-item-selected {
            padding: 0px 2px;
            .list-item-check {
                display: inline-block;
            }
        }
    }
}