@import '../../../styles/variables.less';

.close-claim-confirmation {

  .modal-body {
    width: 265px;
    padding: 5px 0px 0px;
    margin: 0 auto;
  }

  .modal-footer {
    padding-top: 22px;
    padding-bottom: 19px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .confirmation-claim-close-checkbox {
      font-size: @font-size-normal;
      line-height: 26px;

      label {
        min-height: 0px;
        padding-left: 0px;
        margin-bottom: 0px;
      }

      input {
        width: 16px;
        height: 16px;
        margin-left: -26px;
        margin-top: 6px;
      }
    }
  }
}
