@import '../../../../../styles/variables.less';

.importProgressText {
  margin-left: 20px;
}

.loadingWrap {
  display: flex;
  align-items: center;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  height: 15rem;
}

.progressWrap {
  display: flex;
  flex-direction: column;
}

.modalFooterWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.requirementsWrap {
  display: flex;
  align-items: top;

  > h3 {
    margin-right: 10px;
  }

  span {
    font-weight: 600;
    margin-right: 10px;
  }
}

.importCSVWrap {
  .updatingUsersInfo {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid transparent;
    border-radius: 4px;
    width: 95%;
  }
  .radioHorizontal {
    display: flex;
    justify-content: space-between;
    margin-right: 50px;
  }

  .radioHorizontal > div {
    margin-left: 0;
  }
}

.importErrorsWrap {
  p {
    margin-bottom: 30px;
  }

  .importSuccessWrap {
    fill: @color-ui-green;
    color: @color-ui-green;
    display: flex;
    align-items: center;
    align-content: center;
  }

  .importErrorWrap {
    fill: @color-ui-red;
    color: @color-ui-red;
    display: flex;
    align-items: center;
    align-content: center;
  }

  .statusWrap {
    margin-bottom: 15px;
  }

  .downloadIcon path {
    fill: white;
  }

  .iconsWrap {
    margin-right: 10px;
  }
}