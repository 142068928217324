@import '../../../styles/variables.less';

.modal-backdrop {
  background-color: transparent !important;
}

.confirmation-modal {
  .modal-header {
    padding: 9px 12px 0px;
    border-bottom: 0;
  }

  .modal-content {
    border: 0;
    box-shadow: none;
    text-align: center;
    border-radius: 8px;
  }

  .modal-body {
    padding: 7px 15px;
  }

  .modal-footer {
    padding: 10px 0 25px 0;
    border: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .confirmationButton {
      width: 154px;
      height: 37px;
      margin: 0 5px;
    }
  }

  .modal-dialog {
    width: 416px;
    border-radius: 8px;
    background-color: @color-white;
    box-shadow: 0 2px 50px 0 rgba(183,188,208,0.6);

    .modal-title {
      color: @color-black;
      font-size: @font-size-big;
      font-weight: @font-weight-bold;
      line-height: 26px;
      text-align: center;
      padding-top:23px;
    }
  }
  .confirmation-modal-footer{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: flex-end;
    .confirmation-modal-buttons{
      display: flex;
      flex-direction: row;
      margin-top: 10px;
    }
  }
}
