@import '../../../styles/variables.less';

.orgSettingsInfo {
  margin-bottom: 30px;
}

.usersMessageWrap {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  column-gap: 10%;

  .infoText {
    width: 85%;
    margin-bottom: 30px;
  }

  .line-dropdown-link {
    flex-direction: column;
    border-bottom: 1px solid @color-gray;
    min-width: 190px;
  }

  .labelText,
  .previewLabel {
    color: @color-dark-gray;
    margin-bottom: 10px;
  }

  .labelText {
    margin-top: 10px;
  }

  section {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  label {
    margin-bottom: 0;
  }
}

.orgSettingsButtonRow {
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 99999;

  .button-row {
    padding-bottom: 30px;
  }
}

.previewWrap {
  width: 100%;
  height: 100%;
  word-break: break-word;

  .loading-notification {
    height: inherit;
    width: 100%;
    background-color: white;
    border: none;
    border-radius: 0;
    margin: 0;
  }

  strong {
    font-weight: 600;
  }

  .previewBox {
    border: 1px solid #9aa5b1;
    background-color: #cfd7df;
    height: 70%;
    overflow: auto;
    padding-bottom: 30px;
    white-space: pre-wrap;
  }

  .sentText {
    background-color: white;
    width: fit-content;
    height: fit-content;
    margin-left: 20px;
    margin-top: 20px;
    border-radius: 16px 16px 16px 0px;
    padding: 10px;
    margin-right: 10px;
  }

  .receivedTextWrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 30px;
  }

  .receivedText {
    background: #1b73e7;
    border-radius: 16px 16px 0px 16px;
    width: fit-content;
    height: fit-content;
    margin-top: 10px;
    padding: 10px;
    margin-right: 10px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
