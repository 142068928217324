@import '../../../../styles/variables.less';

.template-mgmt-page {
  overflow: auto;
  height: 100%;
  .modal-page-header,
  .message-templates {
    width: 100%;
  }
  .modal-page-header {
    display: flex;
    justify-content: space-between;
    button {
      height: 40px;
    }
  }
}
@column-max-width: 384px;
@column-min-width: 128px;

.users-action-dropdown {
  .RightSlot {
    padding-left: 0;
  }
  width: 240px !important;
  .DropdownMenuCheckboxItem {
    height: 40px;
  }
  span {
    margin-left: 8px;
  }
}

.users-action-dropdown-btn {
  padding: 10px !important;
  span {
    color: @font-color-shark !important;
  }
  &:hover {
    background-color: @color-gray-sand;
  }
}

.user-mgmt-page {
  .count-search-bar {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .active-operator-count {
      width: 50%;
      font-size: @font-size-bigger-rem;
      margin: 0;
      font-weight: 500;
    }
    .users-search-bar {
      margin-right: 0;
      min-width: 180px;
      width: 100%;
    }
  }

  .modal-page-title {
    width: 100%;
    .title-actions-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .user-actions {
        display: flex;
        align-items: center;
        column-gap: 20px;
        height: 40px;
        button {
          height: 40px;
        }
        .marley-banner {
          margin: 0;
          color: @color-shuttle-gray;
          font-size: @font-size-medium-rem;
          border-color: @color-cornflower-blue;
          background-color: @color-blue-malibu;
          padding: 5px 15px;
          display: flex;
          align-items: center;
          height: 40px;
          a {
            color: @color-shuttle-gray;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .generic-user-photo text {
    fill: white;
  }

  .table-col-phone-number {
    padding-right: 32px;
  }

  .table-col-email,
  .table-col-okta-user-id {
    max-width: @column-max-width;
    min-width: @column-min-width;
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: 32px;
    flex: 2;

    .table-cell-label-container {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    .table-row-item-label {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .table-row-copy-icon {
        display: none;
        height: 20px;
        width: 20px;
        min-height: 20px;
        min-width: 20px;
        margin-left: 16px;
        &:hover {
          cursor: pointer;
        }
        &:active {
          filter: invert(51%) sepia(28%) saturate(2%) hue-rotate(314deg)
            brightness(93%) contrast(88%);
        }
      }

      &:hover .table-row-copy-icon {
        display: block;
      }

      div {
        max-width: @column-max-width;
        width: calc(100% - 20px - 16px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .table-col-score {
    width: 100px;
  }

  .table-col-activated div {
    color: @color-ui-red;
  }

  .dropdown-menu > li > a {
    padding: 8px 8px;
  }

  .dropdown-menu > li.dropdown-item-userdelete > a {
    color: @color-ui-red;
  }

  .dropdown-menu > li.dropdown-item-userdelete {
    svg {
      fill: @color-ui-red;
    }
  }

  .dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
  }

  .importBannerLeft {
    display: flex;
    align-items: center;
    .alert {
      margin-bottom: 0;
    }
    button {
      margin-right: 20px;
      height: 40px;
    }
  }

  .searchExportWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-end;
    span {
      color: @color-gray-nevada
    }
    .downloadIcon {
      justify-content: space-evenly !important;
      flex-direction: row-reverse !important;
      min-width: 57px !important;
      height: 40px;
    }
  }
  .usersSortWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    button {
      min-width: 180px;
    }
  }
  .searchSortWrap {
    display: flex;
    align-items: center;
    column-gap: 25px;
  }

  .searchExportWrap,
  .usersSortWrap {
    row-gap: 5px;
  }

  .table-row {
    height: 60px;
  }
}
.create-operator {
  .modal-content {
    width: 80%;
  }
}
