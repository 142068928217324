@import '../../../../styles/variables';

.file-drop-target-inner {
  display: none;
}

.file-drop-dragging-over-frame {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  z-index: 1000;
  opacity: .9;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  justify-content: center;
  align-items: center;

  .file-drop-target-inner {
    display: block;
  }
}

.dropdownMenu {
  left: 0px;
  right: initial;
  z-index: 9999;
}

.chat-input-component {
  background-color: #fff;

  .chat-input-panel {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 16px 0 12px;
    border: 1px solid #CFD7DF;
    border-radius: 4px;
  }

  .chat-input-translate-bar {
    background-color: @color-marley-red;
    font-family: @font-family-table;
    font-size: @font-size-normal;
    font-weight: @font-weight-bold;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    margin-top: 8px;
    margin-bottom: -16px;
    border-radius: 4px 4px 0 0;
  }

  .chat-input-bottom-left-area {
    display: flex;
  }
}