@import "../../../styles/variables";
@color-ai: @color-dark-gray;
@color-ai-background: #DFF4F4;

.editable-line {

    &.has-error {
        .editable-line-display-label {
          color: @color-red-error;
          font-weight: @font-weight-bold;
        }

        .editable-line-help-text {
            color: @color-red-error
        }
      
        .form-field .input-field{
          border-bottom: 1px solid @color-red-error;
        }
    }
    
    .editable-line-help-text {
        font-size: 12px;
    }

    .editable-line-placeholder {
        color: @grey;
    }

    .editable-line-display-label {
        color: @grey;
        font-size: 12px;
        font-weight: 600;
    }

    .editable-line-value-container, .editable-line-value-disabled {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 28px; // to accomodate the halo on icon buttons

        .form-field {
            padding-bottom: 0px;
        }

        .editable-line-edit-button {
            display: none;
        }
        .text-red {
          color: red;
        }
    }

    .editable-line-value-container.ai-generated-value { 
        background-color: @color-ai-background;
        padding: 4px;
        border-radius: 4px;
    
        .editable-line-display-value {
            width: calc(100% - 20px);
            margin-right: auto;
        }
        
        .ai-stars {
            margin-left: auto;
            align-self: flex-start;
        }
    }

    .editable-line-value-container {
        &:hover .editable-line-edit-button, .editable-line-edit-button-always {
            display: flex;
            svg {
                height: 16px;
            }
        }
    }  
}