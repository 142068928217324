@import "../../../styles/variables.less";

.chatComponent {
  display: flex;
  flex-direction: column;
  background-color: #f6f6f8;
  // box-shadow: 0px 2px 50px rgba(183, 188, 208, 0.25);
  border-left: 1px solid #dddfe6;
  width: 150%;
  z-index: 4;
}

.chatComponent-state-box {
  border-right: 1px solid #dddfe6;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  display: flex;
  height: 100%;

  .chatComponent-message {
    font-weight: 600;
    font-size: 26px;
    color: @color-dark-gray;
  }

  .chatComponent-helpMessage {
    color: @color-dark-gray;
  }
}