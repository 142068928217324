@import '../../../styles/variables.less';

.scroller {
    max-height: 200px;
    overflow-y: scroll;
}

.operator-item {
    padding-left: 5px;
    height: 32px;
    border: 0;
    overflow: hidden;
    font-size: 14px;
    line-height: 26px;
    color: #000000;

    &:hover {
        background-color: #F0F3F7;
        cursor: pointer;
    }
}

.search-options-none-found {
    border: 0;
    text-align: center;
    font-size: @font-size-normal;
    padding: 52px 0px 90px;
    color: @color-dark-gray;
    display: flex;
    align-items: center;
    justify-content: center;
}

svg.checkmark.glyphicon {
  display: inline !important;
}
