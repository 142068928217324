@import "../../../styles/variables.less";

.marley-modal-container, .marley-slide-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1149;

    &.marley-modal-container {
        .floating();
    }

    &.marley-slide-modal-container {
        position: absolute;
        width: 100%;
        height: 100%;
        align-items: flex-end;

        .marley-modal {
            box-shadow: 0px 2px 4px #B7BCD0;
            margin: 8px;
        }
    }

    .marley-modal-veil {
        .floating();
        z-index: 1150;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
        outline: 0;
        background: rgba(38, 52, 86, 0.25);
    }

    .marley-modal {
        background: @color-white;
        box-shadow: 0px 2px 8px rgba(255, 255, 255, 0.25);
        border-radius: 8px;
        width: 600px;
        z-index: 1151;
        padding: 10px 20px 20px;
        position: relative; 
        
        &.marley-modal-sm {
            width: 448px;
        }

        &.marley-modal-xl {
            width: 800px;
        }

        &.marley-modal-auto {
            width: auto;
            flex: 1;
            display: flex;
            flex-direction: column;
        }

        .marley-modal-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: -3px;
            padding-bottom: 20px;
        }

        .modal-buttons {
            display: flex;
        }
    }
}
