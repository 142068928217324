@import "../../../styles/variables";

.change-password{
  height: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;

  .change-password-error{
    font-size: 12px;
    color: @color-ui-red;
  }
  .change-password-message-button{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: end;
    // height: 100%;
    .sucsess-change-password-saved, .error-change-password-saved{
        justify-content: flex-end;
        margin-bottom: 5px;
        display: flex;
        font-weight: 600;
        font-size: 14px;
        color: @color-ui-green;
        margin-right: 17px;
    }
    .error-change-password-saved{
        color: @color-ui-red;
        margin-right: 0px;
    }
  }
}