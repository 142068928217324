@import '../../../styles/variables.less';

.resetpassword {

  .password-reset-resetting-button {
    background-color: #3ED9B6;
  }

  .modal-body {
    .message {
      width: 288px;
      margin: 0 auto;
      // padding-bottom: 47px;
      font-size: @font-size-normal;
    }

    .sent-message {
      color: @color-lightest-blue;
      font-size: @font-size-big;
      font-weight: @font-weight-bold;
      margin-top: -19px;
      margin-bottom: 2px;
    }

    button {
      margin-bottom: 45px;
      width: 238px;
    }
  }
}
