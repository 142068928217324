.error-modal {
  .modal-content, .modal-content h4 {
    color:  #DE2C42;
  }
  .modal-content {
    padding: 17px 25px 40px 25px;  
    background: linear-gradient(0deg, rgba(222, 44, 66, 0.2), rgba(222, 44, 66, 0.2)), #FFFFFF;
    border-radius: 8px; 
    box-shadow: 0px 2px 50px rgba(222, 44, 66, 0.2);
  }
  .modal-title {
    font-size: 20px; 
    font-weight: 600; 
    text-align: center;
  }
  .modal-header {
    border-bottom: 0 none;
  }
}
