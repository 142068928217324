@import '../../../styles/variables.less';

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.analytics-dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;

  .analytics-bar {
    display: flex;
    justify-content: space-between;

    .csv-button a {
      padding: 9px;

      /* &:focus:active, &:focus, &:active {
        border: 0;
        text-decoration: none;
        color: white;
      } */
    }

    .csv-button a:hover,
    .csv-button a:active,
    .csv-button a:hover:enabled,
    .csv-button a:active:enabled {
      text-decoration: none;
      color: white;
    }
  }

  .analytics-dashboard-title {
    color: #16212E;
    font-family: @font-family-title;
    font-size: 28px;
    line-height: 33px;
  }

  .analytics-dashboard-panel-container {
    flex: 1;
    display: flex;
    padding-top: 23px;

    .delta-red {
      color: #DE2C42;
    }

    .delta-green {
      color: #3ACCAA;
    }

    .analytics-panel:not(:last-child) {
      border-right: 1px solid #CFD7DF;
    }

    .analytics-panel {
      flex: 1;
      display: flex;
      flex-direction: column;

      .analytics-panel-loading {
        text-align: center;

        .loading-icon {
          -webkit-animation: spin 2s linear infinite;
          -moz-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
        }
      }

      .none-found-notification {
        font-size: 12px;
        margin: 0 auto;
        width: 70%;
      }

      .analytics-panel-title {
        color: #1F3042;
        font-size: 20px;
        font-weight: @font-weight-bold;
        line-height: 23px;
        text-align: center;
        padding: 16px 0px 8px 0px;
      }

      .analytics-panel-data-box {
        display: flex;
        flex-direction: column;

        .analytics-panel-data-row {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: baseline;

          .analytics-panel-data-box-stat {
            color: #1F3042;
            font-family: @font-family-table;
          }

          .analytics-panel-data-box-delta {
            color: #5E7388;
            font-family: @font-family-table;
            padding-left: 2px;
          }
        }

        .analytics-panel-data-box-label {
          color: #5E7388;
          font-family: @font-family-table;
          text-align: center;
        }
      }

      .analytics-panel-box-top {
        height: 125px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 4px 0 #B7BCD0;
        flex: 0 0 125px;
        z-index: 1;

        .analytics-panel-data-box-stat {
          font-size: 32px;
          font-weight: @font-weight-bold;
          letter-spacing: -3px;
          line-height: 43px;
        }

        .analytics-panel-data-box-delta {
          font-size: 14px;
          line-height: 19px;
        }

        .analytics-panel-data-box-label {
          font-size: 14px;
          line-height: 19px;
          text-align: center;
        }
      }

      .analytics-panel-box-middle {
        display: flex;
        height: 75px;
        background-color: #F0F3F7;
        justify-content: space-evenly;

        .analytics-panel-data-box {
          margin: 16px 0px
        }

        .analytics-panel-data-box-stat {
          font-size: 20px;
          letter-spacing: -1px;
          line-height: 26px;
        }

        .analytics-panel-data-box-delta {
          font-size: 14px;
          line-height: 19px;
        }

        .analytics-panel-data-box-label {
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          padding-top: 2px;
        }
      }

      .analytics-panel-box-bottom {
        background-color: #FFFFFF;
        box-shadow: 0 2px 4px 0 #B7BCD0;
        flex: 1;
        display: flex;
        padding-top: 24px;
        flex-direction: column;

        .analytics-panel-data-box {
          flex: 1;
          display: flex;
        }

        .analytics-panel-box-chart {
          padding: 9px 16px;
          text-align: center;
          display: flex;
          flex: 1;

          .bar-chart-label {
            padding: 0px 8px;
          }

          .bar-chart-label-column-right {
            text-align: left;
          }
        }
      }
    }
  }
}