@import "../../../../styles/variables.less";

.trigger-class {
  width: 100% !important;
}
.content-class {
  min-width: 200px;
}
.content-class {
  &.DropdownMenuContent {
    width: auto !important;
  }
  .chatMenu-resolve-release-issues {
    width: 24px;
    height: 24px;
  }
}
// TODO: can we delete these styles?
.chat-component-header {
  display: flex;
  align-items: center;
  height: 64px;
  background-color: #FFFFFF;
  padding: 8px 16px;
  border-bottom: 1px solid #dddfe6;
  overflow: hidden;
  z-index: auto;

  .fullpage-modal {
    width: 80%;
    height: 92%;
    margin-left: -20%;
    box-shadow: 0 2px 50px 0 rgb(183 188 208 / 60%);
    border-color: transparent;
    border-radius: 8px;
    background-color: #FFFFFF;

    .fullpage-modal-body {
      .fullpage-modal-transparent-div {
        width: 40px;
      }
    }

    .large-look-iframe {
      padding-right: 40px;
    }
  }
  .chat-component-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    flex: 1;
    .chat-component-avatar{
      width: 30px;
      height: 30px;
      .header-user-photo{
        width: 30px;
        height: 30px;
      }

      svg{
        width: 24px;
        height: 24px;
      }

      path{
        fill: #5E7388;
      }
    }
    .chat-component-text-wrap{
      display: flex;
      flex-direction: column;
      flex: 1;
      .chat-component-text-name {
        font-weight: 600;
        font-size: @font-size-big;
        line-height: 15px;
      }
      .chat-component-text-id{
        font-family: @font-family-table;
        font-size: @font-size-normal;
      }
      .chat-component-case-id {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .chat-component-assigned-header {
        font-weight: 600;
      }
    }
    .chat-component-assigned-create-case-btn {
      right: 180px;
    }
    .chat-component-assigned-no-create-case-btn {
      right: 20px;;
    }
    .chat-component-assigned{
      position: absolute;
      z-index: auto;
      top: 15px;
      .dropdown .open ul{
        display: none;
      }
      .chat-component-assigned-text{
        line-height: 14px;
        font-size: @font-size-normal;
        margin-right: 10px;
        text-align: end;
      }
      .chat-component-icon-bootstrap{
        padding: 0;
        margin-right: 2px;
        border: none;
        background-color: transparent!important;

        .chat-component-information-icon{
          width: 20px;
          height: 20px;
          fill: #5D6478;
          &:hover{
            fill: #000000;
          }
        }
      }
      .chatHeaderModal {
        right: 10px;
        border-radius: 8px;
        background-color: #fff!important;
        box-shadow: 0 2px 4px 0 @color-gray;
        padding: 2px 8px;
        width: 260px;

        .menuItem {
          color: @color-black;
          font-family: @font-family-table;
          font-size: 12px;
          cursor: pointer;
          padding: 8px 0;
          display: flex;
          &:hover{
            text-decoration: none;
            background-color: @color-gray-background;
          }
          &:focus{
            text-decoration: none; 
          }
          .chat-component-icon-min {
            display: flex;
            align-items: center;
            height: 18px; // for ie11
            &:focus{
              outline: none;
            }
            .menu-icon {
              margin-right: 7px;
            }
          }
          .chat-component-icon-min-red{
            stroke: @color-ui-red;
            color:  @color-ui-red;
          }
          .chat-component-icon-min-green{
            stroke: @color-ui-green;
            color:  @color-ui-green;
          }
        }
      }
    }
  }
}

.chat-component-more-button {
  visibility: hidden;
}

@media only screen and (max-width: @mobile-width) {
  .chat-component-more-button {
    visibility: visible;
  }
}