@import '../../../styles/variables.less';

.collapsible-section {
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;

  &.collapsible-section-open {
    border-bottom: 1px solid #CFD7DF;
  }

  &.collapsible-section-closed {
    flex: 0 0 56px;
  }

  .collapsible-section-body-open {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
  }

  .collapsible-section-body-closed {
    display: none
  }

  .collapsible-section-header {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: #16212E;
    border-bottom: 1px solid #CFD7DF;
    
    &.collapsible-section-header-open {
      border-bottom: none;
    }
  }
  .collapsible-section-subheader {
    font-family: @font-family-standard;
    color: @color-dark-gray;
    font-weight: bold;
    font-size: 12px;
    line-height: 19px;
    padding-right: 8px;
  }
}

.collapsible-section-header-enabled {
  cursor: pointer;
}

.collapsible-section-header-disabled {
  cursor: not-allowed;
}

.collapsible-section-body-float {
  position: absolute;
  z-index: 1000;
  width: 225px;
  padding-top: 20px;
  border-radius: 8px;
  background-color: #FFFFFF !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 20px 8px 8px;
  margin: 0px;
  border: 0;
  float: left;
  text-align: left;
}

.collapsible-caret-open, .collapsible-caret-closed {
  cursor: pointer;
}

.collapsible-caret-open {
  svg {
    transform: rotate(180deg);
  }
}
