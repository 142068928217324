@import '../../styles/variables';

.mass-notification-page {
    width: auto;

    .table-col {
        flex-basis: unset !important;
        flex-grow: unset !important;
        margin: 0px;
    }

    .table-col-notification-name,
    .table-col-date-created,
    .table-col-date-scheduled,
    .table-col-type {
        width: 15%;
        min-width: 180px;
    }

    .table-col-sent-messages,
    .table-col-total-messages {
        width: 10%;
        min-width: 180px;
    }

    .table-col {
        margin: 0px;
    }

    .sort-table-data::-webkit-scrollbar {
        width: 0 !important
    }

    .sort-table-data {
        overflow-y: scroll;
    }

    .table-col-status {
        min-width: 250px;
    }

    .table-col-assigned,
    .table-col-undefined {
        max-width: unset;
        justify-content: flex-start;
        min-width: 188px;
    }

    .table-col-actions {
        width: 100px;
        justify-content: flex-start;
    }

    .sort-row {
        flex-direction: row;
        justify-content: flex-start;
        padding: 0px;
    }

    .table-col span {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .validation-complete,
    .ready-to-send {
        color: green;
        cursor: pointer;
    }

    .sort-table-data-inner {
        padding-bottom: 400px;
    }

    .validatorSpinner {
        margin-left: 10px;
    }

    .errorStatus {
        color: #f9685d;
    }

    .validatorSpinner div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .table-col span {
        position: relative;
    }

    .sendOverlay {
        cursor: pointer;
    }

    .sendOverlay:hover {
        text-decoration: underline;
    }
}

.hm-tab-list-mass-notifications+.hm-tab-content {
    height: calc(100% - 66px);
}