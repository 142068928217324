@import '../../styles/variables.less';
@import "../../styles/animation";

.media-item-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: relative;
  border-radius: 4px;
  transform-origin: top left;
  /* IE 10+, Firefox, etc. */
  -webkit-transform-origin: top left;
  /* Chrome */
  -ms-transform-origin: top left;
  /* IE 9 */

  .media-item-info-icon {
    position: absolute;
    top: 4px;
    right: 4px;
    padding: 2px;
  }

  .media-expand-button{
    background-color: transparent;
    position: relative;
    border: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    border-radius: inherit;
    display: flex;
    position: relative;
  }

  &.rotate90 {
    transform: rotate(90deg) translateY(-100%);
    -webkit-transform: rotate(90deg) translateY(-100%);
    -ms-transform: rotate(90deg) translateY(-100%);
  }
  &.rotate180 {
    transform: rotate(180deg) translate(-100%, -100%);
    -webkit-transform: rotate(180deg) translate(-100%, -100%);
    -ms-transform: rotate(180deg) translateX(-100%, -100%);
  }
  &.rotate270 {
    transform: rotate(270deg) translateX(-100%);
    -webkit-transform: rotate(270deg) translateX(-100%);
    -ms-transform: rotate(270deg) translateX(-100%);
  }
  .media-loading {
    height: 100%;
    width: 100%;
    .wave-mixin();
    background: #F0F3F7;
    border-radius: 4px;
  }
}

.virus-scan-media-item {
  justify-content: flex-start;
}