@import "../../styles/variables";

.title-notification {
  position:absolute;
  top: 76px;
  color: @color-marley-red
}

.modal-header-button-row {
  .button-row {
    display: flex;
    flex: 1;
    flex-direction: row;

    .date-form {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      cursor: pointer;
      
    }

    .active-queue-buttons {
      display: flex;
      flex-direction: row;
      margin-left: auto;

      .marley-dropdown {
        padding-left: 20px
      }
  
      .case-count {
        box-sizing: border-box;
        height: 17px;
        width: 17px;
        border: 1px solid #3BC7EB;
        background-color: #FFFFFF;
        display: inline-block;
        border-radius: 8px;
        text-align: center;
        color: #3BC7EB;
        margin-right: 5px;
      }
  
      .default {
        color: #000;
      }
  
      .hymarley-button {
        font-weight: 300;
        padding: 8px;
        margin: 0px 4px;
        min-width: auto;
        // text-decoration: none;
        &:active { border-style: outset; }
  
        &:active:enabled, &:focus:enabled, &:focus:enabled {
          outline: 0;
          border: 1px solid transparent;
        }
  
        &.link {
          padding: 8px;
        }
      }
    }
  }
}

.modal-page-title-right {
  .active-queue-buttons {
    display: flex;
    flex-direction: row;
    cursor: pointer;

    .case-count {
      box-sizing: border-box;
      height: 17px;
      width: 17px;
      border: 1px solid #3BC7EB;
      background-color: #FFFFFF;
      display: inline-block;
      border-radius: 8px;
      text-align: center;
      color: #3BC7EB;
      margin-right: 5px;
    }

    .default {
      color: #000;
    }

    .hymarley-button {
      font-weight: 300;
      padding: 8px;
      margin: 0px 4px;
      min-width: auto;
      // text-decoration: none;
      &:active { border-style: outset; }

      &:active:enabled, &:focus:enabled, &:focus:enabled {
        outline: 0;
        border: 1px solid transparent;
      }

      &.link {
        padding: 8px;
      }
    }
  }
}
