@import "../../../styles/variables";

.star-icon{
    position: relative;
    top: 1px;
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    &-large{
        font-size: 21px;    
    }
    &-small{
        font-size: 10px;    
    }
    &:before {
        content: "\E006";
    }
}