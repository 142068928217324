@import "../../../../styles/variables";

.icon-button-veil:not([disabled]) {
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;

  &:hover, &:active {
    background-color: #9FBBE8;
    border-radius: 20px;
  }

  &:hover {
    opacity: .3;
  }

  &:active {
    opacity: .6;
  }
}

.icon-button{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border: 1px solid transparent;
  background-color: transparent;
  box-shadow: none;
  box-sizing: border-box;
  height: 28px;
  width: 28px;
  cursor: pointer;

  &[disabled]{
    background-color: @color-gray-background;
    color: @color-gray;
    cursor: default;
  }
}

.icon-button-with-text {
  display: flex;
  cursor: pointer;
  align-items: center;
  &:hover {
    text-decoration: underline;
  }
}
