@import "../../styles/variables";

.user-photo2 {
  width: 34px;
  height: 34px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
  
  .user-photo2-initials{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    color: white;
  }
  .user-color {
    background-color: @color-marley-blue;
    text-transform: uppercase;
  }
  .operator-color {
    background-color: @color-marley-red;
  }
  .removed-color {
    background-color: @color-misty-blue;
  }
  .user-photo2-img{
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
}
