.search-dropdown { 

    .search-bar {
        padding-bottom: 0px;
    }

    .filter-input {
        border: none;
        height: 24px;
        .search-icon {
            display: none;
        }
        input { padding: 0; border-radius: 0; }
    }

    .search-dropdown-link {
        width: 100%
    }

    .marley-dropdown-caret {
        position: absolute;
        right: 10px;
        z-index: 5;    
    }
}