@import '../../../styles/variables';
.lobTrayContainer {
  top: 62px;
  display:  none;
  section {
    height: calc(100vh - 300px);
    button {
      background: @color-catskill-white;
    }
  }
  label {
    color: @color-mirage;
    font-weight: 400;
    font-size: 1.5rem;
    margin-bottom: 15px;
    margin-top: 15px;
  }
}

.selectedLoBCard {
  background: @color-white !important;
  border: 1px solid @color-dodger-blue !important;
  &[disabled] {
    border: 1px solid #9aa5b1 !important;
    div {
        background: transparent !important;
        border-color: transparent !important;
        svg {
          path {
            fill: #16212E !important;
          }
        }
      
    }
  }
}

.unselectedLoBCard {
  background: @color-catskill-white !important;
  &:hover {
    background: @color-white !important;
  }
}

.nameWrap {
  display: flex;
  align-items: center;
  span {
    font-size: 1.1rem;
    font-weight: 600;
    margin-left: 10px;
  }
}

.lobCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin-bottom: 12px;
  padding: 0;
  label {
    display: none;
    margin-right: 0;
  }
  box-shadow: none !important;
}

.infoLabel {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
