@import '../../../../../styles/variables';

.file-upload-indicator {
  align-items: end;
  flex: 1;
  display: flex;
  height: 100px;

  .loading-overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 38px;
    left: 38px;
  }

  .cancel-overlay {
    position: absolute;
    left: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    top: 0px;
    cursor: pointer;
  }

  .loading-veil {
    position: absolute;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .4;
    background-color: #ddd;
    border-radius: 4px;
  }

  img,
  .generic-doc {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 4px;
  }
}