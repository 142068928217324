@import './variables.less';
@import './animation.less';

.modal-page {
  width: 100%;
}

/* Search Bar */

.search-bar-wrap {
  .search-bar {
    margin-right: 20px;
    width: 267px;

  }
}

.search-bar {
  // input:active {
    input::placeholder {
      color: @color-gray !important;
    }

    input:focus::placeholder {
      color: @color-dark-gray !important;
      outline-width: 3px;
    }
  // }
}

/* Buttons */

.btn-marley {
  color: @color-white !important;
  font-size: @font-size-normal;
  border: 0;
}

.btn-marley:hover {
  color: @color-white !important;
}

.btn-marley-disabled {
  background-color: @color-gray-for-disabled !important;
  color: @color-gray !important;
}

.btn-marley-outline {
  color: @color-dark-gray  !important;
  font-size: @font-size-normal  !important;
  border: 1 solid @color-dark-gray  !important;

  &:focus {
    outline-width: 3px;
  }

  &:hover {
    color: @color-white  !important;
    background-color: @color-dark-gray !important;
  }

  &:active:hover {
    outline: none !important;
    color: @color-white  !important;
    background-color: @color-dark-gray-plus20  !important;
  }
}

.btn-marley-red {
  background-color: @color-marley-red !important;
  color: @color-white;
}

.btn-marley-red:hover {
  background-color: @color-marley-red-lighter20  !important;
}

.btn-marley-red:focus {
  background-color: @color-marley-red  !important;
  outline-width: 3px;
  color: @color-white  !important;
}

.btn-marley-red:active:hover {
  outline: none !important;
  color: @color-white;
  background-color: @color-marley-red-darker30  !important;
}

.btn-marley-green {
  background-color: @color-marley-green;
  color: @color-white  !important;
}

.btn-marley-green:hover {
  background-color: @color-marley-green-lighter20;
}

.btn-marley-green:focus {
  background-color: @color-marley-green;
  outline-width: 3px;
  color: @color-white  !important;
}

.btn-marley-green:active:hover {
  outline: none !important;
  color: @color-white  !important;
  background-color: @color-marley-green-darker30;
}

.dropdown&.open button.btn-dropdown {
  color: @color-darkest-gray !important;
}

.dropdown button.btn-dropdown {
  .dropdown-mixin;
  height: 32px;
	border: 1px solid @color-darkest-gray;
  border-radius: 3px;
  background-color: @color-white !important;
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: space-between;

  color: @color-darkest-gray !important;
  font-size: 14px;
  // line-height: 4px;

  &:hover {
    background-color: @color-darkest-gray !important;
    color: @color-white !important;
  }
}

.btn-dropdown:active:hover, .open .dropdown-toggle {
  background-color: @color-dark-gray-plus20 !important;
  color: @color-white !important;
}

.open .dropdown-toggle .caret {
  transform: rotate(180deg);
}

.dropdown-menu {
  border-radius: 8px;
  background-color: @color-white  !important;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  padding: 8px;
  margin: 0px;
  border: 0;

  #popover-contained {
    margin-bottom: 0px;
    border: 0;
    box-shadow: none;
  }

  .panel {
    border: 0;
    box-shadow: none;
    margin-bottom: 0;
  }
}

.bth-marley-primary, .bth-marley-primary:hover, .bth-marley-primary:focus:hover, .bth-marley-primary:active:hover, .bth-marley-primary:focus, .bth-marley-primary:active:focus .bth-marley-primary:active{
  margin: 16px auto 24px !important;
  background-color: @color-ui-green !important;
  color: @color-white !important;
}


.marley-form-group{
  .assign-user-dropdown-button{
    border: 1px solid @color-gray-for-input;
  }
  &.has-error{
    label{
      color: @color-red-error;
      .text-danger{
        color: @color-red-error;
      }
    }
    .assign-user-dropdown-button{
      border: 1px solid @color-red-error;
    }
    .filter-input{
      .form-control{
      border-radius: 15px;
    }
  }
  }
}
.button-standart{
  height: 34px;
  width: 128px;
  color: #fff;
  background-color: @color-button-secondary;
  border: 1px solid transparent;
  border-radius: 3px;
}
.button-standart:hover{
  background-color: @color-button-secondary;
  filter: brightness(120%);
}
.button-standart:focus{
  border-radius: 3px;
  background-color: @color-button-secondary;
  border: 1px solid @color-lightest-blue;
  box-sizing: border-box;
}
.button-standart:active, .button-standart:visited{
  filter: brightness(70%);
}

button.cls-btn {
  position: absolute;
  right: 7px;
  width: 35px;
  height: 27px;
  top: 7px;
  background-image: url('/images/icons/close.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: none;
}

button.cls-btn:hover {
  opacity: 1;
}
