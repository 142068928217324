@import "../../../styles/variables";

.general-info {
    padding: 40px;
    height: 100%;
    overflow-y: auto;

    .user-photo-preview {
        border-radius: 50%;
        overflow: hidden;
        width: 80px;
        height: 80px;
    }

    .change-photo-panel {
        padding-bottom: 15px;

        .help {
            font-style: italic;
            color: @font-color-grey;
            font-size: 14px;
            margin-top: 6px;
        }
    }

    .error-profile-saved {
        color: @color-ui-red;
        margin-right: 0px;
    }
}

.changePhotoWrap {
    position: relative;
    height: 80px;
    width: 80px;
}

.iconDeleteBtn {
    position: absolute !important;
    right: -8px;
    top: -8px;
    border: 2px solid white;
}

.file-input-wrapper {
    position: relative;

    .hidden-file-input {
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: pointer;
    }
}

.changeEmailInfo {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid transparent;
    border-radius: 4px;
    width: fit-content;
}