.quote-container {
  height: 283px;	
  width: 667px;
  .personal-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and ( max-width: 1526px ) {
  .quote-container {
    height: 245px;	
    width: 632px;
  }
}