.hm-feature-flag-list {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.hm-feature-flag-item {
  box-sizing: border-box;
  color: #fff;
  display: block;
  margin: 1px;
  padding: 16px;
}

.hm-feature-flag-true {
  background-color: #390;
}

.hm-feature-flag-false {
  background-color: #999;
}
