@import '../../../../styles/variables.less';

.inboxHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background: @color-white;
  border-bottom: 1px solid @color-light-grey;
  padding: 20px 16px 12px 16px;

  .sortDropdownItemsContainer {
    :global(.IconButton) {
      padding: 0;
    }
  }

  .h1 {
    font-size: @font-size-bigger-rem;
    font-weight: 400;
    color: @font-color-shark;
    margin: 0;
  }

  .top {
    display: flex;

    .h1 {
      color: @font-color-shark;
      font-weight: @font-weight-regular;
    }
  }

  .middle {
    view-transition-name: inbox-middle;
    display: flex;
    height: 32px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: @color-white;

    .top {
      display: flex;

      .h1 {
        color: @color-black;
        font-size: @font-size-bigger;
        font-weight: @font-weight-regular;
      }
    }

    .middle {
      display: flex;
      height: 32px;
      margin-bottom: 20px;
    }
  }

  .inboxSubHeader {
    view-transition-name: inbox-sub-header;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    min-height: 24px;
  }
}

@media only screen and (max-width: @mobile-width) {
  .inboxSubHeader {
    margin-bottom: 0 !important;
  }
  .sortDropdownItemsContainer {
    span {
      width: auto !important;
    }
  }
}

.sortDropdownItems {
  position: fixed;
  left: -154px;
  width: 260px !important;
  :global(.RightSlot) {
    padding-left: 0;
  }
}

.footer {
  view-transition-name: inbox-footer;
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: row;
  justify-content: space-between;

  > div {
    margin-top: 10px;
  }
}

.item {
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterIcon {
  display: block;
  height: 20px;
}

.filterIconWithBadge {
  visibility: visible;
}

.filterIconWithoutBadge {
  visibility: hidden;
}

.badge {
  position: relative;
  top: -34px;
  left: 20px;
  margin-right: 0 !important;
  z-index: 100;
}

.emptyDiv {
  height: 32px;
  width: 83px;
}