@import "../../../../styles/variables";

.checkbox-input {
  display: inline-block;
  .checkbox-sub {
    position: relative;
    display: flex;
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
     //  display: none;
      top: 0;
      left: 0;
      height: 14px;
      width: 14px;
      background-color: #fff;
      border: 1px solid #aaa;
      border-radius: 4px;

      &.checkmark-display {
        border: 1px solid #aaa;
      }
    }
  }
}

/* On mouse-over, add a grey background color */
.checkbox-sub:hover input ~ .checkmark {
  background-color: #eee;
  border: 1px solid #aaa;
}

/* When the checkbox is checked, add a blue background */
// .checkbox-sub input:checked ~ .checkmark {
//   background-color: white;
//   border: 1px solid #aaa;
// }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-sub input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-sub .checkmark:after {
  left: 4px;
  top: 0px;
  width: 6px;
  height: 10px;
  border: solid #333;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

  .checkbox-input-box {
    .input-area();
  }
.checkbox-input-label{padding-left: 5px}
  .checkbox-input-help {
    .secondary-body-text();
    color: @color-black-38;
    margin: 8px 0px 16px;
  }

