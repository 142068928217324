@import "../../../../styles/variables.less";

.time-input {
    width: 100px;
    .input-component();
    margin-bottom: 0px;

    .marley-dropdown-container-inner {
        width: 115px !important;
    }

    .time-input-menu {
        max-height: 200px;
        overflow: auto;
    }

    .text-input-line {
        margin-bottom: 0px;
    }
}