/****** V2 started April 2021 *******/
/**** Please be conscientious about changes in this section *****/

/**** 
  Title & Text Types
****/

/* 
  Header 1 - Section Headers
  Header 2 - ?
  */

/* Header 3 - Most modal headers */
.header-3() {
  font-size: 24px;
  font-weight: 600;
}

/* Subheader Title - Secondary modal or card headers */
.subheader-title() {
  font-size: 18px;
  font-weight: 600;
}

/* Primary Body Text - For most body text */
.primary-body-text() {
  font-size: 15px;
}

/* Secondary Text - Selection items: buttons, input fields, searchbar, etc. */
.secondary-body-text() {
  font-size: 14px;
}

.small-text() {
  font-size: 10px;
}

/* 
  Poppins Font Family
*/
@font-family-standard: "Poppins", "Helvetica", sans-serif;

/*** Colors ***/

@color-black-87: rgba(0,0,0,.87); /* future - 87% opacity */
@color-black-60: rgba(0,0,0,.60); /* future - 60% opacity */
@color-black-38: rgba(0,0,0,.38); /* future - 38% opacity */
@color-tangerine: rgba(255, 186, 135, 1);
@color-tangerine-new-needs-attention: rgba(255, 186, 135, 1);
@color-tangerine-old-needs-attention: rgba(0, 0, 0, 0.12);
@font-color-shark: #242627;

// chat card colors
@shade-of-reddish: #F2685F;
@customerName: #242627;
@grey: #5E7388;


.floating() {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.rounded-box() {
  border-radius: 8px;
}

.input-component() {
  margin-bottom: 16px;
}

.input-line() {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  padding: 0px 0px 3px;

  &:focus, &:placeholder-shown {
    border-top: none;
    border-left: none;
    border-right: none;
  }
}

.input-area() {
  .rounded-box();
  width: 100%;
  resize: none;
  padding: 8px;
  border: 1px solid @color-black-60;
  outline: @color-black-60;
  color: @color-black-60;
  outline: none;
  
  &:focus {
      border: 1px solid @color-black-87;
      outline: @color-black-87;
      color: @color-black-87;
  }

  &:placeholder-shown {
      border: 1px solid @color-black-38;
      color: @color-black-38;
      outline: @color-black-38;
  }

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: @color-black-38;
  }
  
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: @color-black-38;
  }
  
  &::-ms-input-placeholder { /* Microsoft Edge */
    color: @color-black-38;
  }
}

.wrap-text() {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

/**** End V2 ****/

@left-panel-width: 80px;
@right-panel-width: 240px;
@min-app-width: 200px;
@authenticated-width: @min-app-width - (@left-panel-width + @right-panel-width);
@header-height: 60px;
@mobile-width: 768px; // This matches Chakra UI's breakpoint for "md" screens



@color-black: #000; /* future - 87% opacity */
@color-dark-gray: #5E7388; /* future - 60% opacity */
@color-light-grey: #D4D9DD; /* future - 38% opacity */
@color-just-black: #1F3042;
@color-white: #FFFFFF;
@color-darkest-gray: #1F3042;
@color-dark-gray-plus20: #4A5467;
@color-lightest-gray: #fbfbfb;
@color-marley-blue: #44C7E9;
@color-marley-yellow: #FEE743;
@color-marley-red: #F9685D;
@color-marley-red-lighter20: #f9766c;
@color-marley-red-darker30: #b85754;
@color-marley-redaction-red: #BE3320;
@color-marley-green: #44E9C4;
@color-marley-green-lighter20: #51d6b8;
@color-marley-green-darker30: #39b19b;
@color-marley-green-darker40: #048668;
@color-gray-for-input: #d8d8d8;
@color-gray-for-disabled: #E5E5E5;
@color-gray-for-disabled-dropdown: #f4f4f4;
@color-gray-for-preview: #f5f7fa;
@color-gray: #b7bcd0;
@color-gray-chateau: #9AA5B1;
@color-ui-red: #DE2C42;
@color-lightest-blue: #3BC7EB;
@color-blue-cerulean: #08A1C8;
@color-black-38: rgba(0,0,0,.38);
@color-alto: #E0E0E0;
@color-mirage: #16212E;
@color-catskill-white: #F0F3F7;
@color-dodger-blue: #2E88FF;
@color-zircon: #EBF1FF;
@color-zumthor: #E3EBFF;
@color-caribbean-green: #00AB84;
@color-prussian-blue: #384A73;
@color-dark-slate-blue: #263456;
@color-misty-blue: #CBD2D9;
@color-tuna: #36383D;
@color-athens-gray: #F2F3F7;
@color-aqua-haze: #F4F7F9;
@color-limed-spruce: #3E4C59;
@color-fiord: #495A6A;
@color-mischka: #DDDFE6;
@color-chablis: #FFF0EF;
@color-red-robbin: #DE0A26;
@color-gray-nevada: #616E7C;
@color-brand-tangerine: #FCA463;
@color-seashell-peach: #FFF6EF;
@color-ebony-clay: #1F2933;
@color-nevada: #616E7C;
@color-shuttle-gray: #52606D;
@color-cornflower-blue: #62B3EE;
@color-blue-malibu: #1B73E71A;
@color-gray-sand: #323F4B0D;

@list-item-height: 24px;
@list-item-margin-top: 4px;
@list-item-margin-bottom: 4px;
@list-default-num-items: 5;

@color-red-error: #DE2C42;
@color-ui-green: #3ED9B6;
@color-gray-background: #f5f5f5;
@color-blue-background: #1B73E7;
@color-gray-background: #CFD7DF;
@color-gray-text-email: #77767b;
@color-button-secondary: #5c6377;
@color-button-secondary-lighter20: #6d7385;
@color-button-secondary-darker30: #495467;
@color-pending-button: #55829a;
@color-input-focus: #1E3043;

@hover-lighten: 20%;
@active-darken: 30%;



.dropdown-mixin() {
  border-radius: 3px;
  background-color: @color-white;
  box-shadow: 0 2px 4px 0 @color-gray;
}

.modal-mixin() {
  border-radius: 8px;
  width: 75%;
  min-width: 416px;
  background-color: @color-white;
  box-shadow: 0 2px 50px 0 rgba(183,188,208,0.6);
}

//**************************
// below not sure used

@color-marley-brown: #372D24;
@color-green: green;

@color-lighter-blue: #F2F6FA;
@color-light-blue: #E5EEFB;
@color-sea-blue: #a7d2e5; // #9EC4F1;
@color-blue: #8FC8E6;
@color-blue-hover: darken(@color-blue, 7%);
@color-light-blue-nav: #DAE9FC;

@color-charcoal-grey: #363636;
@color-medium-grey: #B5C3D4;
@color-lighter-grey: #E5E8EF;

@color-black: #000000;
@color-red: #ED1C24;

@color-blue-border: #97BAE3;

@font-family-title: "Poppins", "Helvetica", sans-serif;
@font-family-table: "Poppins", "Helvetica", sans-serif;

@font-size-biggest: 28px;
@font-size-bigger: 18px;
@font-size-big: 16px; 
@font-size-normal: 14px;
@font-size-medium: 12px;
@font-size-small: 10px;

// rem sizes relative to 16px 
@font-size-rem: 1rem; // 16px  temp until we can safely change body to 16px
@font-size-medium-rem: 0.75rem; // 12px
@font-size-normal-rem: 0.875rem; // 14px
@font-size-bigger-rem: 1.125rem; // 18px
@font-size-biggest-rem: 1.5rem; // 24px

@font-color-black: #2C3E50;
@font-color-grey: #788995;
@font-color-grey-two: #65768A;
@font-color-grey-three: #969696;

@font-weight-light: 300;
@font-weight-regular: 400;
@font-weight-medium: 500;
@font-weight-bold: 600;

