@import '../../styles/variables.less';

.collapsible-section-body-float {
  position: absolute;
  z-index: 1000;
  width: 225px;
  padding-top: 20px;
  border-radius: 8px;
  background-color: #FFFFFF !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 20px 8px 8px;
  margin: 0px;
  border: 0;
  float: left;
  text-align: left;
}
.caret-open {
  transform: rotate(180deg);
}
.collapsible-section {}
