@import '../../styles/variables.less';

.media-toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.toolbar-action-menu {
  button {
    padding: 8px;
  }
  svg {
    fill: @color-nevada;
    path {
      fill: @color-nevada;
      height: 2rem;
      width: 2rem;
    }
  }
}

.media-toolbar-item {
  margin: 0;
  display: block;

  + .media-toolbar-item {
    margin-left: 10px;
  }
}
