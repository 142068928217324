@import "../../../styles/variables.less";

.data-download-table {
  margin-top: 16px;
  max-width: 800px;

  .table-col-icon {
    max-width: 60px;
    margin-right: 40px;
  }

  .sort-row-header .table-col {
    flex-grow: unset;
  }

  .report .table-column {
    width: 345.5px;

  }

  .icon .table-column {
    width: 93px;
    opacity: 0;
  }

  .report .table-column,
  .lastRun .table-column {
    font-weight: 600;
    font-size: 16px;
  }

  .lastRun .table-column {
    width: 345px;
  }

  .sort-row-data {
    box-shadow: none;

  }

  .table-col {
    font-size: 14px;
  }

}

.dl-icon:hover {
  cursor: pointer;
}