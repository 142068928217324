.formInputWrap {
  display: flex;
  width: 100%;

  .link-button-small {
    display: flex;
    align-items: center;
    text-align: right;
    color: #44C7E9;
    border-style: none;
    margin-left: 131px;
    background-color: #ffffff;
  }
}