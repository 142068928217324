@import "../../../../../styles/variables";

.chat-message2 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.message-failed {
  background-color: #FFF0EF;
}

.message-failed-notice {
  color: #B00020;
  font-size: 12px;
  margin-top: 20px;
  display: flex;
}

.old-needs-attention {
  background-color: #F6F6F6;
}

.new-needs-attention {
  background-color: #FDF7E6;
}

.sentiment:hover {
  background-color: #FDFAF3;
}

.message-icon-bar {
  .icon-bar {
    right: 15px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.message-photo {
  min-width: 34px;
  min-height: 34px;
  margin-right: 8px;
  margin-left: 15px;
  display: flex;
}

.chat-message2 {
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: auto;
  padding-right: 15px;

  a {
    color: @color-lightest-blue;

    &:hover {
      color: @color-lightest-blue;
    }

    &:active {
      color: @color-lightest-blue;
      font-weight: 600;
    }
  }

  .message-box {
    flex-grow: 1;
    word-break: break-all;
    font-size: @font-size-normal;
    word-wrap: break-word;
    min-width: 0;
    margin-top: -12px;

    .message-header {
      color: @color-black;
      font-weight: 600;
      font-size: @font-size-big;
      line-height: 18px;
      margin-bottom: -2px;
      display: flex;
      flex-direction: column;

      .message-header-row {
        display: flex;
        flex-direction: column;

        .message-branding {
          height: 16px;
          color: @color-dark-gray;
          font-family: @font-family-table;
          font-size: 12px;
          line-height: 14px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-left: 4px;
        }

        .marley-badge {
          margin-bottom: 2px;
        }
      }
    }
  }

  .message-body {
    color: @color-black;
    font-size: 14px;
    line-height: 18px;
    word-break: break-word;
    margin-top: 4px;
  }

  .message-timestamp {
    color: @color-dark-gray;
    font-family: @font-family-table;
    font-size: 10px;
    line-height: 13px;
    font-weight: 300;
  }



  .coaching-badges-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 5px;
  }
}

.unread-message {
  background-color: fade(@color-marley-blue, 15%);
}