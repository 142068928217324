@import '../../../styles/variables.less';

@keyframes timeout {
    0% {
        width: 0px;
        border-radius: 0 0 0 4px;
    }
    95% {
        border-radius: 0 0 0 4px;
    }
    100% {
        width: 100%;
    }
}

.snack-bar {
    background: #FFF5B4;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    max-width: none !important;
    position: absolute;
    bottom: 24px;
    left: 24px;
    z-index: 999999999 !important;
    display: flex;
    flex-direction: column;

    // We are importing styles from Unity but not the component yet, so we need to override the styling like this
    border: 0 !important;
    align-items: flex-start !important;
    padding: 0 !important;

    .snack-bar-inner-container {
        padding: 16px;
        display: flex;
        align-items: center;
    }

    .snack-bar-error {
        height: 16px;
        width: 16px;
        margin-right: 16px;
        svg {
            height: 16px;
            width: 16px;
        }
    }

    .snackbar-link { margin-left: 24px !important; }

    .snack-bar-text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */
        
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        
        color: #16212E;
    }
    .snackBarBottomWrap {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        flex-direction: row;
        width: 100%;
    }
    .snackBarActionsWrap {
        display: flex;
        align-content: center;
        align-items: center;
        margin-left: 20px;
    }
    .snack-bar-close-button {
        margin-left: 16px;
        height: 16px;
        width: 16px;
        cursor: pointer;

        svg {
            height: 16px;
            width: 16px;
        }
    }

    a {
        margin-left: 5px;
    }

    .snackBarTopWrap {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .snack-bar-progress-bar {
        width: 100%;
        height: 4px;
        border: 1px solid #3498db;
        border-radius: 0 0 4px 4px;
        background-color: #3498db;
        animation: 10s ease-out 0s 1 timeout;
    }
}

// will iterate on snackbar placement in the future
// .top-center-snackbar {
//     left: 41%;
//     bottom: 86%;
// }