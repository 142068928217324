@import "../../styles/variables";

.right {
  display: flex;
  flex-direction: column;
  block-size: initial;
  height: 100%;
  overflow: auto;
  width: 100%;
  align-items: center;
}

.right-inner {
  width: 100%;
  max-width: 680px;
  min-width: 360px;
  height: 100%;
}

.left {
  width: 270px;
  display: flex;
  flex-direction: column;

  .menu-title {

    button {
      display: block;
      padding: 10px 20px;
      text-decoration: none;
      color: var(--chakra-colors-gray-500);
      font-size: 13px;

      &.active {
        color: var(--chakra-colors-gray-900);
        box-shadow:inset 4px 0 0 @color-marley-red;
      }

      &:hover {
        color: var(--chakra-colors-gray-900);
      }
    }

    .menu-label {
      font-size: @font-size-big;
      font-weight: 600;
      margin: 10px 24px 4px 24px;
    }
  }
}
