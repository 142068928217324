@import "../../../../styles/variables.less";

.chat-log-wrap{
    display: flex;
    position: relative;
    height: 100%;
    overflow: auto;

    .chat-log{
       // min-height: 230px;

        .side-chat-event-outline {
          margin: -10px 0px 20px 30px;
          border-left: 1px solid #CFD7DF;
          padding-top: 10px;

          .side-chat-event {
          	border: 1px solid #CFD7DF;
          	border-radius: 4px;
            margin: 4px 30px 0px 24px;
            padding: 10px 7px;

            .message-timestamp {
              font-size: 8px;
              line-height: 10px;
            }

            .message-name {
              font-size: 12px;
              line-height: 12px;
            }

            .message-body {
              font-size: 10px;
              line-height: 12px;
            }

            .message-photo {
              min-width: 24px;
              min-height: 24px;
            }

            .user-photo2 {
              width: 24px;
              height: 24px;
            }
          }
        }

        .author-name-message {
        	color: #8094A7;
        	font-family: @font-family-table;
        	font-size: 12px;
          padding-left: 8px;
        }

        .new-conversation-message {
        	height: 32px;
        	color: #8295A7;
        	font-size: 12px;
        	font-weight: 600;
        	letter-spacing: -0.5px;
        	line-height: 14px;
        	text-align: center;
          padding-top: 8px;
          display: flex;
          justify-content: center;
          align-items: center;

          .participant-name {
            color: @color-marley-red;
          }
        }

        .delimited-log-group {
	         background-color: #F0F3F7;
           padding-top: 10px;

           .user-photo2-initials {
             background-color: #CFD7DF;
           }

           .delimited-message {
            	color: #22374B;
            	font-size: 12px;
            	line-height: 15px;
            	text-align: center;
              padding-bottom: 17px;
           }
        }
        .today-messages-separator {
            text-align: center;
            position: relative;
            .today-messages-separator-text {
                width: 50px;
                position: absolute;
                left: 46%;
                top: -10px;
                background-color: #fff;
                font-weight: 600;
                font-size: @font-size-normal;
            }
            .today-messages-separator-line {
                border-bottom: 1px solid #dddfe6;
                margin: 16px 0;
            }
        }
    }
    .chat-log-back-button {
        background-color: #e4e6eb;
        padding: 4px 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        width: 100%;

        .chat-log-back-button-title{
            font-size: 14px;
            cursor: default;
        }
        .chat-log-back-button-select{
            cursor: pointer;
            font-size: @font-size-normal;
            line-height: 20px;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}
