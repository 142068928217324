@import '../../../styles/variables.less';

.integrationTemplateModal {
  .mentionsInput__highlighter {
    height: auto;
  }
}

.textValue {
  color: @font-color-shark;
  font-weight: 400;
}

.textHeading {
  color: @color-dark-gray;
  font-weight: 400;
}

.customModalHeader {
  div.marley-modal-header{
    div.marley-title {
      font-size: 24px;
      .textValue();
    }
  }
  .marley-modal-body{
    .scrollableDiv {
      height: 600px;
      overflow-y: overlay;
      padding-right: 12px;
    }
    .scrollableDiv::-webkit-scrollbar-track {
      background: @color-white;
    }
    .scrollableDiv::-webkit-scrollbar-thumb {
      background: @color-light-grey;
      border-radius: 4px;
    }
    .scrollableDiv::-webkit-scrollbar {
      width: 8px;;
    }

    .fullRow {
      margin: 20px 0;
    }
    .flexRowEvenly {
      margin: 20px 0 10px 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      > div {
        width: 50%;
        margin-bottom: 10px;
      } 
    }
    .condition {
      display: flex;
      color: @grey;
    }
    .ruleIcon {
      margin-right: 10px;
    }
    .text-input-label {
      .textHeading();
      font-size: 12px;
      margin-bottom: 10px;
    }
    .text-input-box{
      .textValue();
      font-size: 14px;
    }
    .ruleDetailName{
      .textHeading();
      font-size: 12px;
    }
    .ruleDetailValue{
      .textValue();
      font-size: 14px;
    }
    .hm-button-outline{
      border: 1px solid @color-tuna;
      color: @color-tuna;
      height: 40px;
      width: 120px;
    }
    .hm-button-positive{
      border: 1px solid @color-tuna;
      background-color: @color-tuna;
      height: 40px;
      width: 140px;
    }
    .hm-button-disabled{
      border: 1px solid @color-tuna;
      background-color: @color-tuna;
      height: 40px;
      width: 140px;
    }
    .button-row {
      margin-top: 20px;
      button {
        margin-left: 24px;
      }
    }
  }
  .ruleStatus {
    display: flex;
    align-items: center;
    margin-top: 10px
  }
}



