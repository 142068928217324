.animation-rotate {
  animation: rotate linear;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animation-delay-fade-in {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}

.animation-slide-left {
  display: block;
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.animation-slide-dismiss {
   display: block;
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

.animation-fade-in {
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-in {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
  }
}

.wave {
  .wave-mixin();
}

.wave-mixin() {
  overflow: hidden;
  position: relative;

  &::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    animation: wave-animation 1.6s linear 0.5s infinite;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.3),
      transparent
    );
  }
}

@-moz-keyframes wave-animation {
  0% {
    -moz-transform: translateX(-100%);
  }
  60% {
    -moz-transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(100%);
  }
}
@-webkit-keyframes wave-animation {
  0% {
    -webkit-transform: translateX(-100%);
  }
  60% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(100%);
  }
}
@keyframes wave-animation {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  60% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animation-pop-in {
  -webkit-animation: pop-in 0.5s;
  -moz-animation: pop-in 0.5s;
  -ms-animation: pop-in 0.5s;
}

@-webkit-keyframes pop-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes pop-in {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -moz-transform: scale(1);
  }
}

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
