@import '../../styles/variables.less';

.textFont() {
    font-family: @font-family-standard;
    line-height: @font-size-bigger;
    font-size: @font-size-medium;
    color: @color-dark-gray;
    letter-spacing: 0.25px;
}
.svgIcon {
  width: 15px;
  height: 15px;
}
.caseProgress {
  max-height: auto;
  overflow: auto;
  padding-left: 25px;
  padding-right: 5px;
}
.step {
  margin: 0 10px 5px 0;
}

.stepHeader {
  display: flex;
  span {
    .textFont();
    font-family: @font-family-standard;
    font-weight: @font-weight-bold;
    margin-left: 15px;
  }
}
.stepSubHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    .textFont();
    padding-left: 20px;
    font-weight: @font-weight-regular;
  }
}
.stepBody {
  min-height: 10px;
  margin: 5px;
  border-left: 3px dotted @color-misty-blue;
}
.stepBodyComplete {
  border-left: 3px solid @color-dark-gray;
}
.inprogressText{
  span {
    color: @font-color-shark;
  }
}

.showDetailsButton {
  border: none !important;
  color: @color-blue-background !important;
  padding: 0 !important;
  height: auto !important;
  &:active {
    background: @color-white !important;
    color: @color-blue-background !important;
  }
}
.stepContent {
  margin-top: 10px;
}
// Release Issues steps

.releaseIssues {
  list-style-type: none;
  padding-left: 20px;
  li {
    display: flex;
    svg {
      .svgIcon()
    }
  }
}


.releaseListText {
  .textFont();
  margin-left: 5px;
  margin-bottom: 5px;
}

.issuesResolved {
  display: flex;
  padding-left: 20px;
  svg {
    .svgIcon()
  }
  .releaseListText {
    color: @color-marley-green-darker40;
  }
}

.releaseCard {
  margin: 10px;
  padding: 16px !important;
  cursor: text;
  user-select: text;
  .releaseAuthorPhoto {
    width: 24px;
    height: 24px;
  }
  .markAsCompleteButton {
    height: 24px;
    margin-top: 16px;
    margin-bottom: 6px;
  }
}


.releaseHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.releaseAuthor {
  display: flex;
  span {
    .textFont();
    font-size: @font-size-normal;
    margin-left: 10px;
    font-family: @font-family-standard;
    font-weight: @font-weight-bold;
  }
}
.releaseTimestamp {
  .textFont();
  color: @color-dark-gray;
}

.releaseTextHeader {
  font-size: @font-size-normal;
  color: @color-black;
  font-family: @font-family-standard;
  font-weight: @font-weight-medium;
  padding-top: 16px;
  padding-bottom: 6px;
}

.eventlog {
  padding-top: 6px;
  .eventDivider {
    margin-left: 0;
  }
}

.eventBody {
  margin-right: 10px;
  border-left: 3px solid @color-misty-blue;
  padding-left: 10px;
}
.eventStatus {
  .textFont();
  display: flex;
  align-items: center;
  font-family: @font-family-standard;
  font-weight: @font-weight-bold;
  margin: 16px 0;
  svg {
    width: 20px;
    height: 20px;
  }
  span {
    padding-left: 10px;
  }
}
.eventComplete {
  color: @color-dark-gray;
  font-family: @font-family-standard;
}
.eventResolved{
  font-family: @font-family-standard;
  color: @color-marley-green-darker40;
}
.eventUpdate {
  font-family: @font-family-standard;
  color: @color-ui-red
}
.issueOpen {
  color: @font-color-shark
}
.issueResolved {
  text-decoration: line-through;
}
