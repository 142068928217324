@import "../../../../../styles/variables.less";

.auto-replies-actions-icon-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3%;
  width: 100%;

  .auto-replies-actions-toggle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 70%;
    h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 21px;
      margin-top: 10px;
      margin-right: 5%;
    }
    .customToggle {
      margin-right: 10px;
    }
  }
  .auto-replies-actions-icons {
    display: flex;
    flex-direction: row;
    margin-left: auto;
  }
}
.modal-body {
  margin-top: 10px
}
.auto-reply-action-modal-button {
  margin: 0 5px;
}
.auto-replies-actions-delete-error {
  height:10px;
  color: @color-red-error;
}
.auto-replies-actions-error {
  color: @color-red-error;
}
