@import '../../styles/variables.less';

.dropDown{
    margin-top: 48px;
    margin-left: 50px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .outerBox{
        box-shadow: 0px 0px 4px rgba(183, 188, 208, 0.25);
        width: 516px;
        padding : 10px;
        padding-left: 28px;
        .titleBox{
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            user-select: none;
            cursor: pointer;
            .title{
                font-weight: 600;
                font-style: normal;
                font-size: 16px;
                line-height: 19px;
                color: #000000;
                width : 95%;
            }
    
            .dropDownIcon{
                transition : transform ease 300ms;
                width: 5%;
                transform-origin: center;
                transform: scale(.5) rotate(180deg);
               
            }

            .dropDownIcon.dropped{
                transform: scale(.5) rotate(0deg);
            }

         
        }

        .subContainer{
            width: 100%;
            display: none;
            flex-direction: column;
            .dropDescription{
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                color: #000000;
                margin-top : 4px;
            }
        }

        .subContainer.dropped{
            display: flex;            
        }
    }

}