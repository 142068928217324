.has-error .btn-group button {
  border-color: #DE2C42;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.action-dropdown .dropdown a img, .action-dropdown .dropdown a svg {
  height: 22px;
  width: 22px;
  margin-right: 4px;
  vertical-align: top;
  padding-top: 2px;
}

.icon-dropdown {
  button {
    border: none !important;
    padding: 0;

    .caret {
      display: none;
    }
  }
}

.dropdown-menu > li > a {
  padding: 0px 8px;
}

.btn-dropdown-action {
  width: 74px;
}

.action-dropdown .dropdown ul {
  width: 295px;
}

.action-dropdown .dropdown-menu li a {
  padding: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
}
