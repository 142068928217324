@import '../../../styles/variables';

.mainEntry{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-top: 29px;
    margin-bottom: 29px;

    .entryDescription{
        margin-right: 56px;
    }

    .emailIcon{
        transform: scale(1.1) translateY(2px);
        margin-right: 9px;
        margin-left: 6px;
    }
}