@import "../../../../../styles/variables";
@import "../../../../../styles/animation";

.marley-notes {
    padding: 0px 0px 16px 16px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;

    .marley-notes-body {
        overflow-y: auto;
        padding-right: 16px;
        height: 100%;
        user-select: text;
    }

    .copart-link {
        padding-left: 8px;
    }
    .tag-item {
        margin: 10px 0px 10px 8px;
    }

    .marley-modal-container {
        margin: 30px;
        align-items: flex-start;

        .confirmation-modal {
            .marley-title {
                margin: auto;
                color: @color-black;
                font-size: @font-size-big;
                font-weight: @font-weight-bold;
                line-height: 26px;
                text-align: center;
                padding-top: 23px;
            }

            .marley-modal-header {
                padding-bottom: 2px;
            }
        }
    }
}

.note-link {
    word-wrap: break-word;
}

.note-body {
    padding: 8px;
}

.marley-notes-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: #16212E;
}

.marley-notes-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;

    .marley-notes-add-button {
        cursor: pointer;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #1F3042;

        svg {
            margin-left: 10px;
            height: 19px;
            width: 19px;
        }
    }
}

.marley-note-create {
    border: 1px solid #44C7E9;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
}

.marley-note-create-text {
    border: 1px solid #CFD7DF;
    box-sizing: border-box;
    resize: none;
    padding: 8px;
    margin-bottom: 8px;
    font-size: 14px;
    height: 79px;
    outline: none;
}

.mentionsInput__input {
    border: 1px solid #CFD7DF;
    resize: none;
    outline: none;
    height: 79px;
    overflow: auto !important;
    padding: 8px;
}

.mentionsInput__highlighter {
    padding: 8px;
    box-sizing: border-box;
    overflow: hidden;
    height: 79px;
    border: 1px solid transparent;
    outline: none;
}

.mentionsInput__suggestions {
    max-height: 225px;
    overflow: auto;
    top: 20px !important;
    left: 15px !important;
    border: 1px solid #CFD6DD;
    max-width: 240px;
    height: 225px;
    width: 240px;
    z-index: 99999 !important;
}

.mentionsInput__suggestions__loadingIndicator__spinner {
    div {
        height: 49px;
        margin: 0px 0px 4px;
        background: #F0F3F7;
        border-radius: 4px;
        .wave-mixin();

        &:hover {
            border: none;
        }
    }
}


.mentionsInput__suggestions__item {
    padding: 5px 15px;

    &:focus {
        background-color: @color-gray-background;
    }
}

.mentionsInput__suggestions__item--focused {
    background-color: @color-gray-background;
}

.mentionsInput {
    height: auto;
    margin-bottom: 8px;
    font-size: 14px;
}


.marley-note-create-button-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .marley-button {
        min-width: 93px;
        margin-left: 16px;
    }
}

.marley-note-none {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    font-style: italic;
}

.marley-note-author {
    display: flex;
    flex: 1;
    align-items: center;

    .user-photo2 {
        width: 24px;
        height: 24px;
    }
}

.marley-note-author-name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #16212E;
    padding-left: 7px;
}

.marley-note-title {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    text-align: right;
    color: #16212E;
}

.marley-note-createdAt {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 4px;
    background: #F5F5F5;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    text-align: right;

    color: #1E3043;
}

.marley-note-icons {
    display: flex;
    padding-left: 10px;
    cursor: pointer;
    width: 38px;

    svg {
        height: 15px;
        width: 15px;
    }
}

.marley-note-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #1E3043;
    padding-bottom: 22px;
}

.marley-note-footer {
    padding-top: 22px;

    .marley-note-last-updated {
        font-style: italic;
        font-weight: normal;
        font-size: 8px;
        line-height: 11px;
        display: flex;
        flex: 1;
        justify-content: flex-end;
        color: #5E7388;
    }
}

.suggestionWrap {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    width: 180px;

    div {
        margin-right: 5px;
    }
}

.noteSuggestion {
    position: relative;
    z-index: 1;
    color: #3BC7EB;
    text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
        -1px -1px 1px white;
    text-decoration: underline;
    pointer-events: none;
}

.mentionInfoTrigger {
    font-size: large;
    cursor: pointer;
}

.mentioned-user {
    text-decoration: underline;
    color: #3BC7EB;
}

.cancelSaveWrap,
.mentionedUsersWrap {
    display: flex;
}

.mentionedUsersWrap {
    align-items: center;

    span {
        margin-left: 5px;
        color: #5E7388;
        font-size: 12px;
    }
}

.loadingUsersWrap {
    height: 44px;
}

.marley-card-header {
    display: flex;
    padding-bottom: 10px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.noteCard {
    margin-bottom: 16px;
}

.replyButton {
    float: right;
    margin-top: 15px;
}

.cancelSaveBtn, .replyButton {
    min-width: 84px !important;
    width: 84px !important;
}

.divider {
    margin-top: 15px;
    margin-bottom: 15px !important;
}

.marleyCardHighlight {
    background-color: rgba(59, 199, 235, 0.1) !important;
}

.replyCard {
    border: none !important;
    box-shadow: none !important;
}

.deletedNoteWrap {
    color: #5E7388;
}

.disabledMentionsInput {
    height: fit-content;
    pointer-events: all;
    cursor: pointer;
    textarea {
        border-color: transparent;
        max-height: auto;
    }
    .mentionsInput__highlighter, .mentionsInput__control, .mentionsInput__input {
        height: fit-content;
        max-height: auto;
    }
}

.noteText {
    user-select: all;
    cursor: text;
}
