@import "../../../../../styles/variables.less";

.times-container { 
  display: flex;
  flex-direction: row;
  width: 100%;
  .to-heading, .all-day {
    font-weight: 600;
    font-size: 14px;
  }
  .to-heading {
    margin-top: 43px;
    padding: 0 2% 0 2%;
  }
  .all-day {
    margin-top: 40px;
    padding-left: 5%;
    .all-day-checkbox {
      padding-right: 2%;
      margin-right: 10px;
      transform: scale(1.5);
      width: 11px !important;
      height: 11px !important;
      border: 1px solid @color-dark-gray;
      font-size: 9px;
      &:after {
        content: " ";
        background-color: #fff;
        display: block;
        color: @color-dark-gray;
        width: 11px;
        height: 11px;
        visibility: visible;
        border: 1px solid @color-dark-gray;
        border-radius: 3px;
      }
      &:checked:after {
        content: "\2714";
        font-weight: 600;
      }
      &:checked + label {
        font-weight: 600;
        font-size: 1.35rem;
      }
    }
    label { 
      vertical-align: top;
      font-weight: normal;
      color: @color-dark-gray;
      font-size: 12px;
    }
  }
  .times-container-begin, .times-container-end {
    margin-top: 3%;
    h5 {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #16212e;
    }
    .times-row {
      display: flex;
      align-items: center;
      letter-spacing: -0.5px;
      .dropdown {
        .times-dropdown {
          padding-left: 5px !important;
          width: fit-content !important;
          min-width: 104px;
          border-radius: 3px;
          border: 1px solid #8796a6;
        }
        .dropdown-menu,
        .dropdown-menu-right {
          min-width: 104px !important;
          max-width: 104px !important;
          padding: 16px 7px bottom 2px !important;
          border-radius: 3px;
          height: 225px;
          left: 0;
          right: 0;
          overflow: scroll;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
         li a {
          line-height: 2.5;
         }
        }
      }
    }
  }
  
}
