.splash-screen {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo {
  height: 100px;
  width: 200px;

  svg {
    height: 100%;
    width: 100%;
  }
}

.message {
  font-size: 26px;
  margin-top: 0px;
  margin-bottom: 14px;
  color: #5e7388;

  &.error {
    color: red;
  }
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
