@import "../../../../styles/variables";

.sort-row {
  display: flex;
  align-items: center;

  .table-col {
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    flex-basis: 0;

    color: @color-black;
    font-family: @font-family-table;
    font-size: @font-size-normal;
    line-height: 16px;

    a.table-cell-link {
      color: @color-black !important;
    }

    a.table-cell-link:hover {
      text-decoration: underline !important;
    }
    .table-cell-secondary-label {
      margin-top: 3px;
      font-family: @font-family-table;
      font-size: @font-size-small;
      font-weight: normal;
      color: @color-dark-gray;
    }
  }

  .table-col-user-photo {
    padding-right: 32px;
    flex-grow: 0;
    .user-photo, .generic-user-photo {
      margin: 0;
      height: 32px;
      width: 32px;
      border-radius: 6px;
    }
  }
}

.sort-row-header {
  margin-bottom: 13px;

  .table-col {
    color: @font-color-black;
    font-weight: 500;
    font-size: @font-size-normal;
    line-height: 18px;
  }
}

.sort-row-data {
  height: 48px;
  border-radius: 8px;
  background-color: @color-white;
  box-shadow: 0 0 4px 0 @color-gray;
  margin: 2px 2px 8px 2px;
  &.redacted {
    background-color: @color-light-grey;
  }
}