$color-dark-gray: #5E7388;
$color-border-light-gray: #9aa5b1;
$color-gray: #788995;
$color-background-gray: #cfd7df;
$color-blue-cerulean: #08A1C8;
$color-gray-chateau: #9AA5B1;
$color-marley-blue: #44C7E9;
$color-catskill-white: #F0F3F7;
$color-zumthor: #E3EBFF;
$color-misty-blue: #CBD2D9;
$color-gray-nevada: #616E7C;
$color-shark: #242627;
$color-input-focus: #1e3043;

$font-size-rem: 1rem; //16 px
$font-size-medium-rem: 0.75rem; // 12px
$font-size-normal-rem: 0.875rem; // 14px
$font-size-big-rem: 1.125rem; // 18px
$font-size-bigger-rem: 1.3rem; 
$font-size-biggest-rem: 1.5rem;
