@import "../../../styles/variables";
@import "../../../styles/animation";

.ghost {
    .list-item {
        background: #F0F3F7;
        border-radius: 4px;
        .wave-mixin();

        &:hover{
            border: none;
        }
    }
    .default-item {
        height: @list-item-height;
        margin: @list-item-margin-top 0px @list-item-margin-bottom;

        &:hover{
            border: none;
        }
    }
}
