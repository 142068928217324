@import '../../../styles/variables.less';

.mass-notification-creation-modal,
.mass-notification-view-modal {
  .flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .file-drop {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1000;
  }

  .modal-step-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .modal-section-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 20px;
  }

  .modal-section-subtitle {
    font-size: 14px;
    font-weight: 600;
    margin: 4px 0;
  }

  .modal-section-disclaimer {
    font-size: 12px;
  }

  .miniTitle {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 16px;
  }

  .subText {
    margin-bottom: 10px;
    font-size: 14px;
    color: #16212E;
  }

  .fileUploadContainer {
    height: 81px;
    border-radius: 3px;
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 30px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .errorContainer {
    border-color: red !important;
  }

  .csvErrorText {
    fill: @color-ui-red;
    color: @color-ui-red;
    display: flex;
    align-content: center;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .csvErrorText span {
    margin-left: 5px;
  }

  .messageInfoContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;
  }

  .notificationNameContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }

  .notification-scheduled-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    margin-bottom: 40px;
    margin-top: 20px;

    input {
      width: auto;
    }
  }

  .inputTitle {
    font-size: 12px;
    color: #5E7388;
    margin-bottom: 1px;
  }

  textarea {
    resize: none;
    width: 100%;
    height: 174px;
    margin-bottom: 12px;
    padding: 14px;
    font-size: 12px;
    outline: none;
    border: 1px solid #CFD7DF;
  }

  .buttonRow {
    margin-top: 16px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .csvContainer {
    position: relative;
  }

  .csvContainer,
  .uploadBtnContainer {
    width: 50%;
    height: 100%;
  }

  .csvUploadIconAndFileContainer {
    width: 100%;
    padding-left: 25px;
    flex-direction: row;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }


  .csvFilename {
    color: #1F3042;
  }

  .finalFileInput {
    display: none;
  }


  .uploadFileBtn {
    color: white;
    height: 33px;
    width: 136px;
    border-radius: 3px;
    background-color: #1F3042;
    cursor: pointer;
  }

  .uploadBtnContainer {
    position: relative;

  }

  .csvFilename {
    margin-left: 5px;
    text-align: center;
  }

  .csvFilename.beforeDrop {
    color: #CFD7DF;
  }

  .separator {
    position: absolute;
    left: 0%;
    height: 50px;
    width: 1px;
    background: rgba(0, 0, 0, 0.1);
  }

  .customLink {
    color: #16212E;
    text-decoration: underline;
    cursor: pointer;
  }

  .fileStatusMessage {
    position: absolute;
    color: black;
    font-size: 12px;
    bottom: 9px;
  }

  .green {
    color: green;
  }

  .marleySpinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: #f5f0f0bf;
  }

  .messageInputContainer {
    position: relative;
  }

  .warningText {
    color: #5E7388;
    font-size: 13px;
  }

  .errorText {
    color: red;
    width: 80%;
  }
}