@import '../../../../styles/variables';

.optInMessages {
  height: 100%;

  header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: flex-start;

    h1 {
      font-size: 2em;
    }
  }

  h2 {
    font-size: $font-size-bigger-rem;
    text-transform: capitalize;
  }

  :global {
    .divider {
      margin: 0;

      .divider-line {
        border-bottom: 1px solid $color-border-light-gray;
      }
    }

    .radioHorizontal {
      justify-content: flex-start;

      .radio-wrapper {
        margin-left: 0;
      }
    }
  }
}

.messagesSection {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 70%;
  width: 100%;
  margin-top: 30px;
  justify-content: space-between;
  column-gap: 10%;
}

.containerOptIn {
  display: flex;
  height: auto;
}

.welcomeFlowCard {
  width: 50%;
  margin-right: 60px;

  :global {
    .previewMessagesBox {
      height: var(--preview-height, 100%);
    }
  }
}

.infoText {
  width: 85%;
  margin-bottom: 30px;
}

.instructionText {
  color: $color-dark-gray;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  :global {
    .radioHorizontal {
      justify-content: flex-end;
      align-items: center;

      .radio-wrapper {
        margin-left: 40px;
        margin-right: 0;
        margin-bottom: 0;
      }

      .radio-container {
        margin-right: 0;
      }
    }
  }
}

.modalBody {
  display: flex;
  flex-direction: column;
  column-gap: 3%;
  width: 100%;
  align-content: flex-start;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;

  textarea {
    resize: vertical;
  }

  :global {
    .hm-card {
      padding: 16px;
      cursor: auto;
    }

    .textarea-input-label {
      margin-bottom: 5px;
    }

    .textarea-input {
      margin-top: 20px;
    }

    .tipWrap {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      color: $color-dark-gray;
    }
  }
}

.messagesButtonRow {
  width: 100%;
  height: 75px;
  background-color: white;
}

.topContent {
  display: flex;
  flex-direction: row;
  column-gap: 3%;
  width: 100%;
  align-content: flex-start;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}

.modalLeftSection {
  height: 100%;
  overflow-y: auto;
  width: 60%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.optionalEndingBtn {
  all: unset;
  cursor: pointer;
  color: $color-blue-cerulean;
}

.numberLineCardWrap {
  display: flex;
  margin-bottom: 20px;

  h3 {
    margin-top: 30px;
  }
}

.verticalLine {
  height: 90%;
  background: linear-gradient(to right,
      transparent 0%,
      transparent calc(50% - 0.5px),
      $color-gray-chateau calc(50% - 0.5px),
      $color-gray-chateau calc(50% + 0.5px),
      transparent calc(50% + 0.5px),
      transparent 100%);
  margin-top: 10px;
}

.number {
  width: 40px;
  height: 40px;
  background-color: $color-marley-blue;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.numberLine {
  margin-right: 20px;
  margin-top: 10px;
}

.optInModal {
  width: 60% !important;
  min-height: 650px;
  height: 80%;

  :global {
    .marley-modal-body {
      height: 75%;
    }
  }

  :global {
    .marley-title {
      text-transform: capitalize;
    }
  }

  .hm-tab-item {
    text-transform: capitalize;
  }
}

.previewBoxModal {
  width: 40%;
  height: 100%;
  overflow: auto;

  :global .previewMessagesBox {
    height: 100% !important;
  }
}

.orgMessaging,
.customMessaging {
  display: flex;
  justify-content: center;
  color: $color-dark-gray;
  padding: 5px;
  border-top: 0;
  top: 0;
  position: sticky;
}

.orgMessaging {
  background: $color-catskill-white;
}

.customMessaging {
  background: $color-zumthor;
}

.disableOptInMessage {
  background: $color-catskill-white;
  padding: 12px 28px;
  border-radius: 8px;
  text-align: center;
}