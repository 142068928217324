@import "../../styles/variables";

.notification-point {
  z-index: 14;
  height: 8px;
  border-radius: 15px;
  width: 8px;
  margin-right: 3px;
  position: absolute;
  left: -4px;
  top: -4px;
  display: flex;
    justify-content: center;
    align-items: center;

  svg {
    stroke: @color-white;
  }

  &.notification-point-top-left{
    top: -4px;
    left: -4px;
  }

  &.notification-point-bottom-right{
    right: -4px;
    bottom: -4px;
    left:auto;
    top: auto;
    margin-right: 0px;

    &.notification-point-border {
      right: -5px;
      bottom: -5px;
    }
  }

  &.notification-point-border {
    border: 2px solid white;

    &.notification-point-lg {
      height: 14px;
      width: 14px;
      svg {
        height: 14px;
        width: 14px;  
      }
    }
  }

  &.notification-point-lg {
    height: 10px;
    width: 10px;
  }

  &.notification-point-left {
    left: -10px;
    top: auto;
  }

  &.notification-point-relative {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
  }
}

.glow-radius {
  border-radius: 50%;
  margin-right: 3px;
  position: absolute;
  left: -4px;
  top: -4px;
  height: 8px;
  width: 8px;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  transform-origin: center;
  z-index: 100;
}

.glow-radius-dark-background {
  animation-name: dotAnimation;
}

.glow-radius-light-background {
  animation-name: dotAnimationLight;
}


.notification-blue-point {
  background-color: #44C7E9;
}

.notification-red-point {
  background-color: @color-ui-red;
}

.notification-green-point {
  background-color: @color-ui-green;
}


@keyframes dotAnimation {
  from {
    transform: scale(1);
    background: #f5f4a330;
  }
  to {
    transform: scale(1.3);
    background: #f5f4a361;
  }
}

@keyframes dotAnimationLight {
  from {
    transform: scale(1);
    background: #f5f4a330;
  }
  to {
    transform: scale(1.3);
    background: gold;
  }
}
