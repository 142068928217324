@import '../../../../../styles/variables';

.typing-notification {
  min-height: 24px;
  display: flex;
  flex-direction: row;
  font-family: @font-family-table;
  font-size: @font-size-small;
  color: @color-dark-gray;
  padding: 5px 10px;
  .typing-notification-name{
    margin-right: 7px;
  }
  .typing-notification-dots{
    display: flex;
    .typing-notification-dot{
      animation: wave 1.3s infinite;
      &.second-dot{
        animation-delay: -1.1s;
      }
      &.third-dot{
        animation-delay: -0.9s;
      }
    }
  }
  @keyframes wave {
    0%, 60%, 100% {
      transform: initial;
    }
    30% {
      transform: translateY(-7px);
    }
  }
}