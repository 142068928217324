.group-row-container {
  padding: 16px;
  border: 1px solid transparent;
  width: 100%;
  margin-bottom: 2px;

  &:hover {
    border: 1px solid #44C7E9;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 #B7BCD0;
    background-color: white;
  }
}
