@import '../../../styles/variables.less';

.regularParagraph() {
  font-family: @font-family-standard;
  font-size: @font-size-medium;
  line-height: @font-size-bigger;
  font-weight: @font-weight-regular;
}

.release-modal-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
}
.release-timestamp {
  .regularParagraph();
  color: @color-dark-gray;
}

.release-issue-selected {
  background-color: @color-zircon;
  padding-bottom: 15px;
  .textarea-input{
    margin: 0 20px 0 55px;
  }
}

.release-issues-container {
  max-height: 300px;
  overflow: auto;
  margin-top: 20px;
  margin-bottom: 50px;
  .release-issues-error {
    margin: 10px 20px 0 55px;
  }
}
.release-issue-row {
  border-top: 1px solid @color-gray-background;
  &.release-issue-row-error {
    background-color: @color-chablis;
    .textarea-input .textarea-input-box{
      border: 1px solid @color-red-error;
    }
  }
}

.release-issue-checkbox {
  label {
    font-family: @font-family-standard;
    font-weight: @font-weight-medium;
    .checkedLabel {
      color: @font-color-shark;
      font-size: @font-size-big;
      font-family: @font-family-standard;
      font-weight: @font-weight-medium;
    }
    .uncheckedLabel {
      color: @color-dark-gray;
      font-size: @font-size-big;
      font-family: @font-family-standard;
      font-weight: @font-weight-medium;
    }
  }
}
.release-issue-last{
  border-bottom: 1px solid @color-gray-background;
}
.checked-issues-counter {
  display: flex;
  margin-right: auto;
  font-family: @font-family-standard;
  font-size: @font-size-medium;
  color: @color-dark-gray;
  .checked-issues-badge {
    background-color: @shade-of-reddish
  }
}
.release-issues-error {
  .regularParagraph();
  color: @color-red-error;
  display: flex; 
  align-items: center; 
  background-color: @color-chablis;
  padding: 10px;
  border-radius: 10px;
  span {
    margin-left: 5px;
  }
}

.release-issue-select-all {
  border: none !important;
  color: @color-dark-gray !important;
  padding: 0 !important;
  height: auto !important;
  &:active {
    background: @color-white !important;
    color: @color-dark-gray !important;
  }
}

.release-issue-submit-error {
  color: @color-red-error;
  display: flex;
  justify-content: right;
  font-weight: @font-weight-bold;
  padding-bottom: 5px;
}
