@thumbnail-size: 96px;

.details-media-item-container {
  width: @thumbnail-size;
  height: @thumbnail-size;
  overflow: visible;
  margin: 8px;
  background-color: #fff;
  position: relative;
  border-radius: 8px;
  transition: transform .2s;
  &:hover {
    transform: scale(1.1); 
  }
}

.user-details-media-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0;
}
