@dark-background-color: rgba(0, 0, 0, 0.95);

.video-display {
  border-radius: inherit;
  margin: auto;
  max-height: inherit;
  max-width: inherit;
  width: 100%;
  height: 100%;
  background-color: @dark-background-color;
  &.video-display-thumbnail {
    object-fit: cover;
  }
  &.video-display-expanded {
    object-fit: scale-down;
  }
}

.video-thumbnail-overlay-wrap {
  border-radius: inherit;
  width: 100%;
  height: 100%;
}

.video-thumbnail-overlay-wrap svg {
 position: absolute;
 top: 0;
 left: 0;
 background-color: rgba(0, 0, 0, 0.60)
}