@import '../../styles/variables';

.dropdown-item-close a, .dropdown-item-close a img {
  color: @color-ui-red !important;
}

.dropdown-item-close a svg {
  fill: @color-ui-red !important;
}

.archived-claim .table-col {
  color: @color-gray-for-disabled;
}

.button-unassigned {
  width: 100%;
}

.archived-claim .action-dropdown .dropdown-menu li a {
  color: @color-ui-green;
}
.archived-claim .action-dropdown .dropdown-menu li svg {
  fill: @color-ui-green;
}

.claims-search-bar {
  width: 100% !important;
}

/* .table-col-assigned {
  width: 148px;

  >div {
    width: 148px;
  }
} */

/* .claims-wrap {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding: 10px 20px 0 20px;
}

.claim-table {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.claim-table .table-responsive {
  flex: 1 0 auto;
}

.claim-row {
  height: 40px;
  background-color: @color-lighter-blue;
  border-radius: 5px;
} */

/* .claim-col {
  border-top: 1px solid transparent !important;
  vertical-align: middle !important;
  padding: 3px 8px !important;
  border-bottom: 6px solid white !important;
} */

// Table
/* .claim-number {
  position: relative;
  color: @color-black;
  font-size: 12px;
  font-weight: 500;
  padding-left: 11px !important;

  &::before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    height: 40px;
    width: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: @color-green;
  }
} */
