@import '../../../../../../styles/variables.less';

.info-panel {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: white;
  box-shadow: 0 0 4px 0 #B7BCD0;
  width: 350px;
  min-height: 322px;
  margin-right: 40px;
  border: transparent 1px solid;

  .groupAvatar {
    .user-photo2-initials {
      background: linear-gradient(180deg, #91EBDD 0%, #3ACCAA 100%);
    }
  }

  &:hover {
    border: #1F3042 1px solid;
    box-shadow: none;
  }

  .collapsible-section {
    width: 100%;
    margin-top: -34px;
    display: flex;
    flex: none !important;

    .collapsible-section-header {
      display: flex;
      justify-content: flex-end;
      border-bottom: none;
    }

    .collapsible-section-body {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .group-editable {
    .group-edit-icon-button {
      display: none;
      padding-right: 0px;
      width: 24px;

      &.group-edit-icon-button-active {
        display: inline;
      }
    }

    &:hover {
      .group-edit-icon-button {
        display: inline;
      }
    }
  }

  .group-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #16212E;
    margin-bottom: 8px;
  }

  .group-photo {
    display: flex;
    width: 70px;
    min-height: 70px;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    font-size: 20px;
    border-radius: 50%;
    color: white;
    background: linear-gradient(180deg, #91EBDD 0%, #3ACCAA 100%);
  }

  .group-description, .group-leads-title {
    color: @color-dark-gray;
    font-size: 14px;
    line-height: 21px;
    height: 24px;
  }

  .group-description-edit {
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    height: 78px;
  }

  .group-description {
    display: flex;
    justify-content: space-between;
  }

  .group-leads-title {
    padding-bottom: 8px;
  }

  .description {
    padding-bottom: 16px;
    flex: 1;
  }

  svg {
    height: 16px;
    path {
      fill: #5E7388;
    }
  }

  .group-count {
    color: #16212E;
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
  }

  hr {
    background-color: #CFD7DF;
    height: 1px;
    display: block;
    border: 0;
    clear: both;
    width: 100%;
  }

  .group-leads {
    display: flex;
    flex-wrap: wrap;

    .group-lead {
      flex: 0 50%;
      display: flex;
      align-items: center;
      padding-bottom: 8px;

      .user-photo2 {
        padding-right: 4px;
      }
    }
  }

}
