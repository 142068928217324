@import '../../../../../styles/variables.less';

.redactedLog {
  background-color: @color-seashell-peach;
  display: flex;
  padding: var(--spacing-15, 0.75rem) 1rem;
  svg {
    width: 16px;
    height: 16px;
    path {
      fill: @color-brand-tangerine;
    }
    margin-right: 10px;
  }
  strong {
    font-weight: @font-weight-bold;
  }
}

.text {
  font-size: @font-size-medium-rem;
  color: @color-gray-nevada;
  margin-left: 15px;
  color: @color-ebony-clay;
  strong {
    color: @color-ebony-clay;
    font-weight: 600;
  }
}

.ulWrap {
  padding-left: 0;
  margin-bottom: 0;
}

.redactedLabel {
  font-size: @font-size-medium-rem;
}