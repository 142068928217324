@import "../../../../../styles/variables.less";
.auto-reply-no-messages {
    text-align: center;
    p {
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #5E7388;
    }
    .auto-reply-no-messages-error {
        color: @color-red-error;
    }
}