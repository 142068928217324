@import "../../styles/variables";

.left-bar{
  width: @left-panel-width;
  min-width: @left-panel-width;
  display: flex;
  flex-direction: column;
  z-index: 17;
  box-shadow: 0 2px 5px 0 rgba(186,186,186,0.5);
  padding-top: 20px;
  background-color: @color-darkest-gray;
  height: 100%;
  overflow: auto;
  .menu-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0;
    margin-top: 5px;
    padding-bottom: 8px;
    min-height: 66px;
    background-color: transparent;
    position: relative;
    text-align: center;
    .menu-icon{
      &.selected{
        background-color: @color-marley-red;
        color: @color-white;
      }
      background-color: @color-darkest-gray;
      width: 48px;
      height: 48px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px;
      .icon{
        width: 38px;
        height: 38px;
        fill: @color-white;
      }
    }
    .menu-label{
      text-transform: capitalize;
      font-family: @font-family-table;
      color: @color-white;
      font-size: 10px;
      line-height: 13px;
      margin-top: 4px;
    }
  }

  .notification-point, .glow-radius {
    left: 2%;
    top: 10%;
    height: 8px;
    width: 8px;
    animation-name: dotAnimation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
    transform-origin: center;
    z-index: 100;
  }

  @keyframes dotAnimation {
    from {
      transform: scale(1);
      background : #f5f4a330;
    }
    to {
      transform: scale(1.3);
      background : #f5f4a361;
    }
  }
}
