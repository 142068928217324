
@import "../../../../../styles/variables";
.message-editor-container {
  position:relative;
}
.message-editor-popup{
    position: absolute;
    top: -21px;
    left: 0px;
    height: 173px;
    width: 408px;
    border-radius: 8px;	
    background-color: @color-white;
    box-shadow: 0 2px 4px 0 @color-gray;
    z-index: 1000;
    .message-editor-popup-helps{
      margin: 8px 0px;
      display: flex;
      font-size: 9px;
      justify-content: space-around;
      color: @color-gray;
    }
    .message-editor-popup-list{
      display: flex;
      flex-direction: column;
      height: 80%;
      margin: 0 8px;
      overflow: auto;
      
      .message-editor-popup-item{
        color: @color-black;
        font-family: @font-family-table;	
        font-size: 14px;	
        line-height: 26px;
        padding: 0 8px;
      }
      .message-editor-popup-item-active{
        background: @color-gray-background;
        color: @color-black;
        font-family: @font-family-table;	
        font-size: 14px;	
        line-height: 26px;
        padding: 0 8px;
      }
    }
  }