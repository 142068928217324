@import "../../../../styles/variables.less";

.chat-banner-locked {
  background-color: @color-ui-red;
}

.chat-banner-default {
  background-color: @color-dark-gray;
}

.chat-banner {
  height: 38px;
  display: flex;
  padding: 0 16px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 50px rgba(183, 188, 208, 0.25);


  .chat-banner-text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: @font-family-table;
    font-size: 13px;
    display: flex;

    div {
      margin-left: 5px;
    }

    svg {
      fill: white;
    }
  }

  .chat-banner-button {
    background: transparent;
    text-decoration: underline;
    font-family: @font-family-table;
    font-size: 12px;
    color: white;
    border: none;
    padding-left: 10px;
  }
}

.changed-number-info-header {
  background-color: #44C7E9;
}