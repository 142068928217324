@import "../../styles/variables.less";

.new_tog {
  display: flex;
  height: 26px;
  width: 52px;
  border: 0;
  border-radius: 26px;
  align-items: center;
  color: white;
  cursor: pointer;
  padding: 0;

  .toggle-label {
    line-height: 12px;
    font-size: 10px;
    letter-spacing: -.36px;
    font-family: @font-family-table;
    font-weight: 600;
    flex: 1;
    text-align: center;
  }

  .toggle-ball {
    height: 24px;
    width: 24px;
    background-color: white;
    border-radius: 26px;
    margin: 1px;
    border: 1px solid #eee;
  }

  &.on_tog {
    justify-content: flex-end;
    background-color: #3ED9B6;
    flex-direction: row-reverse;
  }

  &.off_tog {
    justify-content: flex-start;
    background-color: #d9534f;
    border-color: #d43f3a;
  }
}
