.forward-media-modal-body {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.forward-media-modal-preview {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex: 0 0 auto;
  margin-right: 2rem;
  width: 96px;
  height: 96px;
}

.forward-media-modal-message {
  margin: 0;
}

.forward-media-modal-error {
  margin: 1rem 0;
}
