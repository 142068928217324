@import "../../styles/variables";

.modal-page {
  display: flex;
  background-color: @color-lightest-gray;
  flex-direction: column;
  padding: 48px 59px 0px 19px;
  height: 100%;

  .modal-page-header {

    .modal-header-button-row {
      padding-bottom: 20px;
    }

    .modal-header-title-row {
      padding-bottom: 26px;
      display: flex;
      justify-content: space-between;
    }

    .modal-page-title {
      line-height: 33px;
      font-size: @font-size-biggest;
      font-family: @font-family-title;
      color: @color-black;
    }
  }

  .btn-add-item {
    height: 34px;
    width: 127.09px;
  }
}

.modal-content {
  margin: 0 auto;
  border-color: transparent;
  .modal-mixin;

  .header-modal {
    position: relative;
    width: 99%;
    display: inline-block;
    padding: 16px 24px;

    .header {
      font-size: 24px;
      margin: 0;
      height: 28px;
      font-weight: 600;
    }
  }

  .body-modal>div {
    .sub-title {
      width: 100%;
      padding: 24px;
      font-size: @font-size-big;
      font-weight: 600;
    }

    #assignee-dropdown {
      width: 100%
    }
  }

  .body-modal {
    // border-top: 1px solid;
    width: 99.6%;
    margin-left: 0.3%;
  }
}