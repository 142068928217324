.large-media-metadata-item {
  min-width: 128px;
}

.media-metadata-item {
  margin-left: 24px;
}

.media-metadata-item-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #5E7388;
}

.media-metadata-item-value {
  margin-top: 4px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #1F3042;
}