.contact-card-container {
  background-color: #e5e8ef;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  max-width: 450px;
  align-items: center;
  justify-content: center;
  column-gap: 15px;
  .contact-card-icon {
    justify-content: center;
    width: 30%;
    img {
      height: 100%;
      width: 100%;
      object-fit: crop;
      border-radius: 50%;
      clip-path: circle();
    }
  }
  .contact-card-header {
    font-size: 1.3rem;
    font-weight: 600;
    justify-content: left;
    text-align: left;
  }
  .contact-card-details {
    padding: 5px;
    align-content: flex-start;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
  }
  .contact-card-row {
    display: flex;
    flex-direction: row;
    justify-content: left;
    position: relative;
    column-gap: 10px;
    vertical-align: 50%;
    align-items: center;
    height: 32px;
    svg {
      height: 100%;
    }
  }
}


