.hm-row {
    display:flex;
    padding: 8px 0px;

    &.hm-row-justify {
        justify-content: space-between;
        gap: 30px;

        >div {
            flex: 1;
        }
    }
}