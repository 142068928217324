@import "../../../styles/variables";

.form-field{
  position: relative;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .form-field-label   {
    font-size: 14px;
    // line-height: 13px;
    font-weight: 400;
    color: @font-color-grey;
    &.error{
      color: @color-red-error;
    }
    .text-danger{
      font-size: 9px;
      font-family:  @font-family-table;
    }
  }
  .form-field-value-wrap{
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    .form-field-prefix {
      position: absolute;
      bottom: 2px;
      left: 1px;
      font-size: 14px;
      line-height: 16px;
    }
    #deductible{
      padding-left: 16px;
    }
  }

  .input-field{
    width: 100%;
    color: @color-black;
    font-family: @font-family-table;
    font-size: 14px;
    line-height: 15px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid;
    border-color: @color-gray;
    box-shadow: 0 0 0px 50px #fff inset; //Disable input decoration with autocomplete style

    &:focus{
      outline: none;
      border-color: @color-input-focus;
    }
    &.error{
      border-color: @color-red-error;
    }
  }

  .help-message{
    position: absolute;
    bottom: 0px;
    font-size: @font-size-normal;
    height: 28px;
    line-height: 15px;
    width: 105%;
  }

  .error-message {
    color: @color-red-error;
  }
}
