@import "../../../../styles/variables.less";

.textarea-input {
    .input-component();

    .textarea-input-box {
        .input-area();
    }

    .textarea-input-box-resize {
        resize: vertical
    }

    .textarea-input-help {
        .secondary-body-text();
        color: @color-black-38;
        margin: 8px 0px 16px;
      }
}