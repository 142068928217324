@import '../../../../styles/variables.less';

.group-mgmt-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  .sort-table-data-inner {
    height: auto !important;
  }
  .group-mgmt-header {
    display: flex;
    justify-content: space-between;
    
    .filter-input input {
      width: 200px;
    }
    button {
      height: 40px;
    }
  }

  .group-mgmt-panel-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 23px 0px;
    overflow: scroll;
  }

  .table-col-email {
    min-width: 200px;
    max-width: 225px;
    div {
      max-width: 100%;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap
    }
  }

  .table-col-score {
    width: 100px;
  }

  .table-col-activated div {
    color: @color-ui-red;
  }

  .dropdown-menu > li > a {
      padding: 8px 8px;
  }

  .dropdown-menu > li.dropdown-item-userdelete > a  {
    color: @color-ui-red;
  }

  .dropdown-menu > li.dropdown-item-userdelete {
    svg {
      fill: @color-ui-red;
    }
  }
  .sort-table {
    margin-bottom: 70px;
    height: auto;
  }
}
