@import "../../../../styles/variables";

.sidebar-panel {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.snack-bar-container {
  position: fixed;
  width: 100%;
  .snack-bar {
    background: #17314A;
    border: 1px solid #3B5268;
    box-sizing: border-box;
    /* dropshadow large */
    
    box-shadow: 0px 2px 50px rgba(183, 188, 208, 0.25);
    border-radius: 8px;
    color: white;    
  }
}

.case-details {

  .form-field {
    width: 50%;
  }

  .editable-line .editable-line-value-container .editable-line-edit-button, .editable-line .editable-line-value-container .editable-line-edit-button-always { display: none }

  .user-name-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: 30px;
    .user-name-title{
      color: #000000;
      font-weight: 600;
      font-size: 20px;
      line-height: 23px;
      width: 100%;
      word-break: break-all;
    }

    .marley-button {
      min-width: 57px;
    }
  }
}

.collapsible-section-notes, .collapsible-section-media {
  .collapsible-caret-closed, .collapsible-caret-open {
    cursor: pointer;
  }
  &.collapsible-section .collapsible-section-body-open{}
}

.user-details-wrapper{
  padding: 16px;
  overflow-y: auto;
  flex: 2;
}
