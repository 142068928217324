@import '../../../../styles/variables.less';

.participants-count-and-view {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.participant-circles-and-add {
  padding-left: 8px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}