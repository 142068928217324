@import "../../../../styles/variables";

.ghost {
  .user-row {
    /* specific to user row */
    height: 49px !important;
    margin: 4px 0px 4px;
    justify-content: center;
    align-items: center;
  }  
}

.user-row {
  display: flex;
  width: 100%;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: @color-gray-background;
  }

  .generic-user-photo, .user-photo, .user-photo2 {
    margin-top: 3px;
    min-width: 30px;
    max-width: 30px;
    height: 30px;
  }

  .user-info {
    padding-left: 8px;
    flex-grow: 1;

    .operator-details{
      display: flex;
      justify-content: space-between;
      align-items: center;
  }

  .user-email{
    padding-left: 45px;
    font-weight: 600;
  }
    .user-name {
      font-size: @font-size-big;
      font-weight: @font-weight-bold;
      white-space: nowrap;
    }

    .company, .title {
      font-family: @font-family-table;
      font-size: @font-size-normal;
      white-space:nowrap;
    }
  }
}
