@import '../../styles/variables';

.table-col.table-col-case-number {
  flex-basis: 250px;
}

.cases-page-container {
  width: 100%;

  .enter-case {
    position: absolute;
    top: -60px;
    width: 100%;
    height: 100vh;
  }

  .search-bar {
    width: 100%;
  }

  .cases-page {
    .body-modal {
      padding-top: 3rem;
    }
    .row-checkbox {
      width: 24px; 
      padding-left: 5px; 
      padding-right: 3px;
    }

    .case-columns {
      display: flex;
      .case-checkbox {
        width: 24px; 
        height: 21px;
      }
    }

    .bulk-action-dropdown .marley-dropdown-container-inner {
      left: 0px;
      width: 226px;
    }

    .modal-header-button-row {
      height: 140px;
      display: flex;
      padding-bottom: 30px !important;

      .active-queue-buttons {
        align-items: center;
      }
    }
    
    .search-bar-wrap {
      justify-content: flex-end;
    }
  
    .closed-case .table-col {
      color: @color-button-secondary;
    }
    
    .select-all-case {
      display: flex;
      padding-bottom: 10px;
      align-items: center;
      .ml-10{margin-left: 10px;}
      position: absolute;
      top: 208px;
      left: 28px;
    }

    .selected-counted {
      font-weight: 'bold';
      padding-right: 5px
    }
    

    .closed-case .action-dropdown .dropdown-menu li.dropdown-item-reopen {
      a {
        color: @color-ui-green;
      }
      svg {
        fill: @color-ui-green;
      }
    }
  }
}
