@import '../../../../../styles/variables';

.sub-message-container {
  margin: 8px 0px 0px 25px;
  border-left: 1px solid #16212E;
  padding: 1px 0px 0px 6.5px;

  .sub-message-header {
  	height: 16px;
  	color: #5E7388;
	font-size: 12px;
  	line-height: 16px;
  }

  .sub-message {
  	height: 18px;
  	color: #16212E;
  	font-size: 14px;
  	letter-spacing: -0.5px;
	line-height: 18px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: auto;  
  }
}
