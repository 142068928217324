@import '../../../../../styles/variables.less';

@color-transcription-text: @color-black;
@color-ai: @color-dark-gray;
@color-link: @color-marley-blue;

.audio-transcription {
    color: @color-transcription-text;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}

.audio-player {
    margin: 6px 0px;
}

.ai-message { 
    color: @color-ai;
    font-size: 12px;
}

.view-more-button {
    padding: 0;
    border: none;
    background: none;
    color: @color-link;
}

.ai-container {
    display: flex;
    align-items: center;
    margin-top: 8px;
}