@import "../../../styles/variables";

.auto-replies {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-z: scroll;

  .auto-replies-container {
    background: #ffffff;
    border: 1px solid #cfd7df;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(183, 188, 208, 0.25);
    border-radius: 8px;
    width: 100%;
    padding-bottom: 40px;

    .auto-reply-row {
      margin-bottom: 16px;
    }
  }
}

.autoreplies-loading-icon {
  text-align: center;
}

.ooo-timeout {
  display: flex;
  justify-content: space-between;
  margin: 25px 0 15px 0;
}

.auto-replies-form-error {
  height:10px;
  text-align: right;
  color: @color-red-error;
}

.line-dropdown {
  flex-direction: row;
}
button.line-dropdown.btn-dropdown {
  padding: 2px 2px 2px 0px;
}
