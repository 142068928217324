@import '../../../../../../styles/variables.less';


.listing-panel {
  display: flex;
  flex-direction: row;
  padding: 24px;
  background: white;
  box-shadow: 0 0 4px 0 #B7BCD0;
  height: 340px;
  flex: 1;

  .membersGroupWrap {
    display: flex;
    align-items: center;
  }

  .modify-group-box {
    width: 175px;
    text-align:left;
    margin-top: -12px;
    color: #5E7388;
    display: flex;
    height: 20px;
    align-items: center;
    justify-content: flex-end;

    button {
      padding: 0;
    }

    svg {
      height: 20px;
      width: 20px;
    }

    .modify-group-button {
      display: flex;
      align-items: center;
      cursor: pointer;

      .icon-button-veil {
        display: none;
      }
  
      svg {
        margin-top: 2px;
        fill: #5E7388;
      }

      &:hover, &:active {
        color: @color-marley-blue;
        svg {
          fill: @color-marley-blue;
        }
      }
    }

    .delete-group-button {
      svg {
        fill: @color-ui-red;
      }
    }
  }

  .sort-table {
    flex: 1;
  }

  .sort-row-data {
    margin-top: 0px;
    margin-bottom: 0px;
    box-shadow: none;
    border-radius: 0px;
    text-transform: capitalize;
    height: 16px;
    padding: 16px 0 16px 0;
  }

  .sort-row-header .table-col{
    color: #537388;
    font-weight: normal;
    font-size: 15px;
  }

  .sort-row-header {
    margin-bottom: 0px;
    background: white;
    padding: 0px 0 14px 0;
  }

  .sort-table-data {
    border-radius: 3px;
    box-shadow: none;
  }

  .sort-table-data-inner {
    padding-right: 0px;
    height: auto !important;
  }

}
