@import "./variables";
@import "./fonts";
@import "./app";
@import "./dashboard";
@import "./chat";
@import "./common";

body {
  font-family: @font-family-standard;
}

button, .btn-link {
 // outline: none !important;
}

[role=button], [role=link], button:focus, .btn-link:focus {
  outline: 0;
}

.clearfix:before, .clearfix:after { content: ''; display: table }
.clearfix:after { clear: both }

// Below this line, not yet sure that use.

.close-icon {
  font-weight: bold
}

.close-button {
  border: 0;
  background-color: transparent;
}

.marley-secondary-btn {
  background-color: @color-white;
  font-size: 11px;
  border: 1px solid @color-charcoal-grey;
  border-radius: 5px;
  height: 36px;
  color: @color-charcoal-grey;
  text-transform: uppercase;
}
