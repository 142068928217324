@import '../../../../styles/variables.less';

.view-participant-list-body {
  padding: 10px 0;
  min-height: 325px;
}

.view-participant-header {
  display: flex;
  flex-direction: row;
  font-weight: 600;

  @media only screen and (max-width: @mobile-width) {
    display: none;
  }
}

.view-participant-header-participant {
  flex: 1;
}

.view-participant-header-phone-number {
  width: 25%;
}

.view-participant-header-participant-status {
  width: 25%;
}

.view-participant-header-actions {
  width: 200px;
  display: flex;
  justify-content: flex-end;
}