@import "../../../../../styles/variables.less";

.box {
  display: flex;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 24px;

  img {
    max-width: 120px;
    max-height: 120px;
  }
}

.title {
  height: 18px;
  color: #16212E;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: -0.5px;
  line-height: 17px;
  margin-top: 24px;
  margin-bottom: 6px;
}

.body {
  height: inherit;
  color: #16212E;
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 18px;
  margin-bottom: 24px;
}

.buttons {
  .left-button {
    margin-right: 24px;
  }
}
