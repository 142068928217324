@import '../../../styles/variables.less';

.wrapper {
  background: @color-catskill-white;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
  align-items: center;
  flex-direction: column;
  height: 30%;
  margin-top: 20px;
  padding-bottom: 50px;
  padding-top: 50px;
  p {
    max-width: 80%;
    margin-bottom: 20px;
  }
}
