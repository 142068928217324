.tooltip-info {
    display: flex;
    justify-content: center;
    align-items: center;
}

.infoSvg{
    width: 16px;
    height: 16px;
}
// Hats off to this http://jsfiddle.net/technotarek/L2rLE/
.tooltip.top .tooltip-inner, .tooltip.bottom .tooltip-inner, .tooltip.right .tooltip-inner, .tooltip.left .tooltip-inner {
    background-color:#354554;
}
.tooltip.top .tooltip-arrow, .tooltip.bottom .tooltip-arrow {
    border-bottom-color: #354554;
}  
.tooltip.top .tooltip-arrow {
    border-top-color: #354554;
}
.tooltip.right .tooltip-arrow {
    border-right-color: #354554;
}
.tooltip.left .tooltip-arrow {
    border-left-color: #354554;
} 

.auto-popover{
    background-color: #F9685D;
    color: #FFFFFF;
} 

.auto-popover .arrow {
    display: none;
  }

.auto-popover::after {
    position: absolute;
    bottom: -5px;
    right: 48%;
    content: '';
    width: 16px;
    height: 16px;
    background:#F9685D;
    transform: rotate(45deg);
}   

