@import '../../../../styles/variables.less';

.brandName {
  display: flex;
  align-content: center;
  align-items: center;
}

.lobPage {
  height: 100%;
  overflow: auto;
}

.lobPage :global .hm-tab-content {
  margin-top: 20px;
}

.lobPage :global .modal-page-title {
  font-size: 2em !important;
  font-family: inherit !important;
}

.lobPage :global &.modal-page {
  height: unset;
}


.lobPage :global .scrollableTemplateList {
  height: calc(100vh - 450px) !important;
}

.brandAvatar {
  margin-right: 10px;
}

.brandListWrap {
  height: calc(100vh - 400px);
  width: 100%;
  overflow-y: auto;

  section {
    margin-bottom: 20px;
  }
}

.expandCollapseBtn {
  all: unset;
  cursor: pointer;
  color: @color-marley-blue;
  width: 100px;
  margin-left: 20px;
  display: flex;
  justify-content: flex-end;
}

.lobRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
}

.nameType {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;

  h3 {
    margin-bottom: 5px;
    text-transform: capitalize;
  }
}

.edit,
.back {
  all: unset;
  cursor: pointer;
}

.edit {
  span {
    color: @color-marley-blue;

    svg {
      transform: rotate(270deg);

      path {
        fill: @color-marley-blue;
      }
    }
  }
}

.back {
  span {
    color: @color-mirage;

    svg {
      transform: rotate(90deg);

      path {
        fill: @color-mirage;
      }
    }
  }
}

.optInHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: white;
  padding-bottom: 20px;
}

.lobSelected {
  >div {
    display: none;
  }
}

.optInWrap {
  margin-top: 20px;
}

.group-mgmt-page {
  background-color: white;
}

.noLobWrap {
  display: flex;
  align-items: center;

  span {
    margin-right: 10px;
  }
}

.lobIcon {
  background: black;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    path {
      fill: white;
    }
  }
}

.orgMessaging,
.customMessaging {
  display: flex;
  justify-content: center;
  color: @color-dark-gray;
  padding: 5px;
  border-top: 0;
  top: 0;
  position: sticky;
}

.orgMessaging {
  background: @color-catskill-white;
}

.customMessaging {
  background: @color-zumthor;
}

.capitalize {
  text-transform: capitalize;
}