.user-details-media {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.user-details-media-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user-details-download-all-button {
  display: flex;
  background-color: transparent;
  border: 0;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: 8px 8px 8px 24px;
  height: 24px;
  svg {
    width: 24px;
    height: 24px;
  }
}

.user-details-media-items {
  padding: 8px 16px 16px 16px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  align-content: flex-start;
  svg {
    max-width: 96px;
    max-height: 96px;
  }
}
