

.case-visibility-toggle {
    width: 90%;
    height: 300px;
    border-top: 1px solid #CFD7DF;
    padding: 15px 0 15px 15px;
    bottom: 0px;

    .title-and-icon {
        padding: 0 0 15px 0;
        .lock-icon {
            position: relative;
            svg {
                position: absolute;
                top:0;
                left: 5px;
            }
        }
    }

    .description {
        color: #5E7388;
        width: 100%;
        padding-right: 60px;
    }
}