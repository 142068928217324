.admin-notifications-intro-text {
  display: flex;
  letter-spacing: 0.5px;
}

.button-container {
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 16px;
  }
}

.admin-notifications-page {
  .infoSvg{
    margin: 0 5px;
  }
  overflow: scroll;
  .admin-notifications-container{
    max-width: 1080px;
    margin-bottom: 50px;
    button:focus{
      border-radius: 16px;
    }
  }
}
