.personal-insights-page {
  display: flex;
  flex-direction: column;
  width: auto;
  min-width: 100%;
  height: 100%;
  padding: 0 80px 0 0; // Size of left-bar width to center items
  background-size: 100% 100%; 
  background-color: #f5f5f5;
  overflow: hidden;
  .personal-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50%;
    .personal-header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 93%;
    }
  }
  .personal-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60%;
    .personal-card-list-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 93%;
      padding: 0 0 100px 0;
    }
  }
}

@media screen and ( max-width: 1526px ) {
  .personal-home-page {
    .personal-bottom {
      .personal-card-list-container {
        padding: 0 0 70px 0;
      }
    }
  }
}
