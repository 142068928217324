@import '../../styles/variables';
@import '../../styles/animation';

.settingsWrap {
  height: 100%;
  width: 100%;

  .modal-page {
    background-color: white !important;
    padding: 0 !important;
  }

  .verticalLayout {
    height: 100%;
    width: 100%;

    .hm-tab-content {
      width: 85%;
      min-width: 85%;
      height: 100%;
    }

    .hm-tab-list-navigation {
      width: 15%;
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: flex-start;

    h1 {
      font-size: 2em;
    }
  }

  .divider {
    margin: 0;

    .divider-line {
      border-bottom: 1px solid #9aa5b1;
    }
  }

  .verticalLayout .hm-tab-list {
    position: sticky;
    top: 58px;
  }
}

.brandsWrap {
  height: 100%;
  width: 100%;

  .text-input-line {
    width: 40%;
    margin-top: 30px;
    color: #788995;
  }

  .requireFields {
    color: #788995;
  }

  .topBar {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 30px;

    .line-dropdown {
      margin-left: 30px;
    }

    .searchSort,
    .sortWrap {
      display: flex;
      align-content: center;
      align-items: center;
    }

    .sortWrap {
      margin-left: 25px;
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: flex-start;

    h1 {
      font-size: 2em;
    }
  }
}

.leftNav {
  flex-grow: 1;
}

.rightContent {
  flex-grow: 2;
}

.deleteModal {
  .brandName {
    color: #788995;
    margin-left: 20px;
  }

  .deleteModalFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  .deleteModalInfo {
    display: flex;
    align-items: flex-start;
    color: #788995;

    span {
      margin-left: 10px;
    }
  }
}

.cancelButton {
  min-width: fit-content !important;
}

.brandInputWrap {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}

.brandListWrap {
  .hm-card {
    padding: 8px 8px 8px 20px;
  }

  .marley-dropdown .marley-dropdown-link {
    padding-top: 10px;
    margin-left: 16px;
  }

  .actionDropdown {
    .marley-dropdown-container-inner {
      max-width: 210px;
      width: fit-content;
      left: 15px;
    }

    .marley-dropdown-link {
      margin: 0;
    }

    .list-item {
      justify-content: flex-start;
    }

    .list-item-icon {
      margin-right: 5px;
    }

    .list-item:last-child {
      color: @color-ui-red;

      svg {
        fill: @color-ui-red;
        color: @color-ui-red;
      }
    }
  }

  .user-photo2-initials {
    border-radius: 25px !important;
  }
}