@import "../../../../styles/variables.less";

.inboxFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: @color-athens-gray;

  div {
    text-align: center;
    color: @color-fiord;
    font-size: @font-size-normal-rem !important;
    margin-bottom: 24px;
    margin-top: 40px;
    max-width: 80%;
  }
}
