@import '../../../../../styles/variables.less';

.base {
  color: @color-dark-gray;
  font-size: 0.875rem;
  line-height: normal;
}

.expandedSearchContainer {
  .base();
  display: flex;
  align-items: center;
  width: 100%;
  .searchBar {
    width: 100%;
  }
  .cancelButton {
    margin-left: 8px;
    font-size: @font-size-medium-rem;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    &:hover,
    &:focus,
    &:active {
      color: @font-color-shark;
    }
  }
}

.collapsedSearchContainer {
  .base();
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 32px;
  .searchText {
    padding-right: 8px;
    cursor: pointer;
    background: none;
    border: none;
  }
  .searchIcon {
    cursor: pointer;
  }
  &:hover,
  &:focus,
  &:active {
    color: @font-color-shark;
    svg,
    path {
      fill: @font-color-shark;
    }
  }
}
