@import '../../styles/variables';

.voiceMessage {
  border: 1px solid $color-misty-blue; // the border to the right is #dddfe6
  border-radius: 8px;
  padding: 20px;
  margin-top: 4px;
  max-width: 900px;
}

.messageInfo {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}

.callIcon {
  margin-right: 16px;
}

.callInfo {
  flex-grow: 1;
  margin-right: 16px;
  color: black;
}

.callType {
  font-size: $font-size-normal-rem;
  font-weight: 500;
}

.callDetails {
  font-size: $font-size-medium-rem;
  color: $color-gray-nevada;
  line-height: 18px;
}

.transcriptButton button {
  white-space: nowrap;
  height: 32px;

  &:hover {
    background-color: #F5F7FA;
  }
  &:active {
    color: $color-input-focus;
  }
}

.messageAudio {
  display: flex;
  justify-content: center;

  audio {
    width: 100%;
  }
}

.trayTitle {
  font-size: $font-size-rem !important;
  color: $color-shark !important;
  font-weight: 400 !important;
}
