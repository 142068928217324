@import '../../../../../styles/variables.less';

.marley-assistant-message {
  height: auto !important;
  padding: 16px !important;
  width: inherit;
  background-color: rgba(240,243,247,0.5);
  display: flex;
  align-items: center;
}

.message-prompt {
  height: auto;
  border-left: 5px solid #F9685D;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
}

.message-info {
  height: 16px;
  color: #5E7388;
  font-size: 10px;
  letter-spacing: -0.5px;
  line-height: 13px;
}

.assistant-name {
  height: 16px;
  color: #16212E;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 17px;
}
