@import '../../../styles/variables.less';

@image-container-height: 70%;
@image-container-padding-bottom: 10%;
@image-metadata-width: 296px; // TODO figure out how to import this correctly
@dark-background-color: rgba(0, 0, 0, 0.95);

.full-screen-media-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: @dark-background-color;
}

.full-screen-media-modal-header, .full-screen-media-modal-footer {
  height: calc((100% - @image-container-height - @image-container-padding-bottom) / 2);
  width: 100%;
}

.full-screen-media-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.full-screen-media-button {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  cursor: pointer;
  &:hover, &:active {
    background-color: #9FBBE8;
    border-radius: 20px;
  }
  svg {
    path {
      fill: @color-white;
    }
  }
}

.full-screen-media-close-button {
  margin-left: 32px;
}

.full-screen-media-action-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 32px;
}

.full-screen-media-count {
  color: white;
  margin-right: 32px;
  user-select: none;
}

.full-screen-media-middle-container {
  height: @image-container-height;
  width: 100%;
  display: flex;
  align-items: center;
}

.full-screen-media-next-image-arrow {
  width: 20%;
  min-width: 20%;
  display: flex;
  justify-content: center;
}

.full-screen-media-container-padding-bottom {
  height: @image-container-padding-bottom;
  width: 100%;
}
