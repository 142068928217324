@import '../../../../../styles/variables.less';

.avatar {
  width: 20px !important;
  height: 20px !important;
  font-size: 0.7rem;

  div:first-child {
    border-radius: 50%;
  }
}

.trigger {
  view-transition-name: inbox-quick-filter;
  width: 100% !important;
  min-width: 260px;
}

.dropContent {
  left: -130px;
}

.mentionsToolTipContent {
  font-size: @font-size-medium-rem !important;
  padding: 8px 12px !important;
  h1 {
    color: white;
    margin-bottom: 5px;
  }
}
