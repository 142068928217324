.marley-cards {
    padding: 0px 0px 16px 16px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .marley-cards-body {
        overflow-y: auto;
        padding-right: 16px;
    }
}

.marley-card-ghost {
    margin-bottom: 16px;
    height: 114px;
}

.marleyCardHighlight {
    background-color: rgba(59, 199, 235, 0.1) !important;
}

.cardWithDot {
    margin-left: 4px;
    margin-top: 4px;
}

.marley-card {
    border: 1px solid #CFD7DF;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
    min-height: 88px;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px #B7BCD0;

    .marley-card-header {
        display: flex;
        padding-bottom: 10px;
        justify-content: space-between;
        align-items: center;
    }

    .marley-card-text {
        padding-bottom: 22px;
    }
}