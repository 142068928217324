
.choose-office-hours-modal {
  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    margin-top: 20px;
  }
  .office-hours-subheader {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 16px;
    display: flex;
    color: #1f3042;
    align-items: center;
  }
  .column-titles {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    h5 {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
    }

    .weekday-column {
      margin-left: 5px;
      .days-row {
        display: flex;
        flex-direction: column;
        .weekday-checkblock {
          width: 100%;
          margin-top: 22px;
          margin-left: 10px;
          .weekday-label {
            font-size: 14px;
          }
        }
      }
    }
    .mulitime-dropdown-container {
      margin-right:25px;
    }
  }

  .begin-end-headings {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 70px;
  }

  .times-container {
    .times-container-begin,
    .times-container-end {
      margin-top: 20px;
      justify-content: space-around;
    }

    .to-heading {
      margin-top: 22px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .timezone-container {
    margin-top: 40px;
    h6 {
      font-size: 12px;
      line-height: 25px;
      margin-bottom: 2px;
      color: #16212e;
    }
    .timezone-dropdown {
      border: 1px solid #1e3043;
      box-sizing: border-box;
      border-radius: 3px;
      width: 225px;
      padding-left: 10px;
    }
  }
}
