@import '../../../../styles/variables';

.remove-template-icon-button {
  margin-bottom: 16px;
  color: #de2c42;
  display: flex;
  justify-content: flex-end;

  svg {
    width: 24px;
    height: 24px;
    fill: #de2c42;
  }
}

.message-templates {
  .marley-dropdown {
    .marley-dropdown-link {
      span {
        flex: inherit;
      }
      display: flex;
      min-width: 140px;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
    }
  }
  display: flex;
  flex-direction: column;
  flex: 100;
  overflow: hidden;

  .text-input-label {
    color: @color-dark-gray;
    margin-bottom: 5px;
  }

  .templatesLabelWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .message-templates-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px;
    .message-templates-header {
      color: @color-black;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
    }
    .message-templates-filter {
      display: flex;
      border: 1px solid @color-dark-gray;
      border-radius: 12px;
      height: 32px;
      min-width: 275px;
      .message-templates-filter-input {
        color: @color-dark-gray;
        font-size: 14px;
        line-height: 18px;
        padding: 0;
        margin: 3px;
        border: 0;
        outline: none;
        width: 100%;
      }
      .message-templates-filter-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;

        svg {
          fill: @color-dark-gray;
          height: 24px;
          width: 24px;
        }
      }
    }
  }
  .message-templates-list {
    overflow: hidden;
    white-space: pre-line;
    height: 100%;

    .scrollableTemplateList {
      height: calc(100vh - 300px);
      overflow: auto;
    }

    .marley-card-header {
      align-items: top;
    }

    .icon-button {
      svg {
        height: 20px;
      }
    }
    .marley-card-editicons {
      height: 41px;
      display: flex;
    }
    .message-templates-empty {
      button {
        height: 40px;
      }
      margin: 20px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .message-templates-empty-icon {
        height: 36px;
        width: 39px;
        margin-bottom: 18px;
      }
      .message-templates-empty-header {
        color: @color-gray;
        font-weight: 600;
        font-size: @font-size-biggest;
        line-height: 33px;
        text-align: center;
      }
      .message-templates-empty-help {
        color: @color-gray;
        font-size: @font-size-big;
        line-height: 17px;
        margin-top: 16px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .countSortSearchBar,
  .searchSort {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .noTemplatesWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: @color-catskill-white;
    padding: 60px;
    border-radius: 8px;
    p {
      margin-bottom: 20px;
      white-space: initial;
    }
  }
  .tipWrap {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    color: @color-dark-gray;
  }

  .marley-modal-container {
    .message-editor-modal {
      .marley-modal-header {
        padding-bottom: 0;
      }
      .requiredWrap {
        color: @color-dark-gray;
      }
      .button-row {
        .icon-button-with-text {
          flex-direction: row-reverse;
          margin-bottom: 0;
        }
        .saveCreateButtons {
          display: flex;
        }
      }
    }
  }
  .templateTitle {
    font-size: 1.4em;
  }
  .titleVisibleToWrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    .text-input-line,
    .lobVisibleTo {
      width: 40%;
    }
    .text-input-label,
    .lobVisibleToLabel {
      color: @color-dark-gray;
    }
  }
  .removeTemplateModal {
    strong {
      font-size: 1.2em;
    }
    p {
      margin-bottom: 10px;
    }
  }
}
