@import '../../styles/variables.less';

.search-bar-button {
  flex-grow: 1;
}

.search-bar {
  .filter-input {
    border-radius: 4px;
    border: 1px solid @color-gray-chateau;
    min-width: 180px;
    width: 100%;
    display: flex;
    height: 32px;
    align-items: center;

    .search-icon {
      height: 32px;
      width: 32px;
      margin-left: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .clear-search-button{
      height: 32px;
      width: 32px;
      margin-right: 4px;
      background: none;
      display: contents;
      justify-content: flex-end;
      align-items: center;
      border: none;
    }

    .clear-search-button svg {
      margin-right: 6px;
    }
    
    .caret-icon {
      padding-right: 13px;
      svg {
        height: 6px;
        width: 6px;
      }
    }

    input {
      border: 0;
      background-color: @color-white;
      border-radius: 12px;
      height: 26px;
      padding: 0px 10px 0px 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: @color-dark-gray;
      box-shadow: none;

      &:focus {
        box-shadow: none;
      }

      ::placeholder, ::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder, :-moz-placeholder {
        font-family: @font-family-standard;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;

        color: @color-dark-gray
      }
    }

    input::placeholder {
      font-family: @font-family-standard;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;

      color: @color-dark-gray
    }

    .input-group-addon {
      border-radius: 0px 7px 7px 0px;
      padding: 5px 8px 3px 8px;
      border: 0;
      background-color: @color-just-black;
      color: @color-white;

      svg {
        fill: @color-white;
        height: 14px;
        width: 14px;
      }
    }
  }
}