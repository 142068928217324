@import '../../styles/variables.less';

.media-item-boundary {
  display: flex;
  justify-content: center;
  position: relative;
  background-color: transparent;
  border-radius: 4px;
  flex-direction: column;

  &.media-item-boundary-thumbnail {
    &:hover {
      transform: scale(1.02); 
    }
  }
  button {
    max-height: inherit;
  }

  .icon-media-upload {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: white;
    border-radius: 50%;
    height: 20px;
    width: 20px;
  }
  .icon-media-upload-spinner {
    height: 24px;
    width: 24px;
  }
}