@import "../../styles/variables";

.form-input-container {
  display: flex;
  flex-direction: column;
  margin: 0 0 30px;
  width: 100%;

  .form-input-label {
    padding: 0;
    font-size: 0.875rem;
    font-weight: @font-weight-medium;
    color: @font-color-grey;
  }

  .disabled {
    cursor: not-allowed;
  }

  .form-input{
    width: 100%;
  }

  .form-input,
  .form-input-prefix,
  .form-input-suffix {
    border: none;
    outline: none;
    background-color: white;
  }

  .form-input-prefix,
  .form-input-suffix {
    padding: 0 0.2rem 0.2rem;
  }

  .form-input-inner-container {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid @color-gray;
  }

  .error-message,
  .field-specific-message {
    margin: 0;
    font-size: @font-size-normal;
    font-weight: 300;
  }

  .error-message {
    color: @color-red-error;
  }

  .field-specific-message {
    color: #737373;
  }
}

.success-message-inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.has-error {
  .form-input-label {
    color: @color-red-error;
    font-weight: @font-weight-bold;
  }

  .form-input-inner-container {
    border-bottom: 1px solid @color-red-error;
  }
}
