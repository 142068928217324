@import '../../../../../styles/variables.less';

.redactText {
  margin-bottom: 20px;
  font-size: @font-size-rem;
  color: @color-gray-nevada;
  div {
    margin-top: 20px;
  }
}

.image {
  img {
    width: 250px;
    height: 250px;
    object-fit: cover;
  }
}

.modal {
  max-width: 32%;
}