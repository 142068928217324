@import '../../../styles/variables.less';

.icon-bar-container {
  position: relative;

  &:hover {
    background-color: #f6f6f6;
    .icon-bar {
      display: flex;
    }
  }
  .icon-bar {
    position: absolute;
    cursor: pointer;
    top: -15px;
    right: -15px;
    display: none;
    box-sizing: border-box;
    border-radius: 16px;
    height: 32px;
    justify-content: center;
    align-items: center;
    padding: 0px 2px;
  }
}
