@import '../../styles/variables.less';
.policies-page-modal {
  min-width: 740px;
  .modal-content {
    width: 600px;
    margin: 0 auto;
  }
  .form-group {
    .control-label {
      font-size: 0.875rem;
      font-weight: @font-weight-medium;
      color: @font-color-grey;
    }
  }

  .user-form-container {
    .alert{
      margin: 0 20px;
    }
  }

  .case-form-container {
    .alert{
      width:110%;
      margin-left:-5%;
    }
  }
}

.policies-search-bar {
  width: 100% !important;
}