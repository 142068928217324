@import '../../../styles/variables.less';

.brandListWrap {
  display: flex;
  column-gap: 3%;
  height: 70%;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  border-bottom: 1px solid @color-gray-chateau;

  .actionDropdown {
    .list {
      .list-item {
        width: 100%;
      }
    }
  }

  .addBrandBtnColumn {
    width: 25%;
    min-width: 330px;
    height: 15%;
    min-height: 112px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: @color-catskill-white;
    margin-top: 8px;
    border-radius: 8px;
  }

  .marley-title-subheader {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
  }

  .deleteModal {
    word-break: break-word;
    .hasLobWrap {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      margin-bottom: 20px;
      .avatarNameWrap {
        display: flex;
        align-items: flex-start;
        width: 50%;
        .brandName {
          margin-left: 0;
          margin-right: 10px;
        }
        .nameTitle {
          word-break: break-word;
        }
      }
      p {
        width: 50%;
      }
    }
  }

  .linesOfBusinessWrap {
    height: 90%;
    overflow: auto;
    .actionDropdown {
      .marley-dropdown-container-inner {
        right: 15px;
        left: auto;
      }
    }
  }

  .hm-row-brand {
    height: 15%;
    min-height: 120px;
    .actionDropdown {
      .marley-dropdown-container-inner {
        right: 15px;
        left: auto;
      }
    }
  }

  .selectedBrandCard {
    border-color: @color-dodger-blue;
  }

  .hm-row-brand,
  .hm-row-lob {
    .hm-card {
      display: flex;
    }
    .hm-row {
      height: 100%;
      width: 100%;
      .nameTitle {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 75%;
      }
      .iconRow {
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
  }
  .hm-row-lob {
    padding-top: 0;
    width: 100%;
    height: 20%;
    margin-bottom: 10px;
    min-height: 95px;
  }

  .verticalDivider {
    display: flex;
    height: 15px;
    align-items: center;
    justify-content: space-between;
    div {
      width: 50%;
      height: 100%;
    }
    .verticalLeftOne {
      border-right: 1px solid @color-gray-chateau;
    }
  }

  .brandLinesColWrap {
    height: 89%;
    width: 25%;
    min-width: 330px;
    .hm-row {
      padding-bottom: 0;
    }
    .ghost {
      border-radius: 8px;
    }
    .ghost:nth-child(1) {
      padding-top: 8px;
    }
    .ghost:nth-child(3) {
      height: 100%;
    }
  }
  .emptyColumn {
    width: 10%;
  }
  .noBrandsWrap,
  .linesOfBusinessWrap {
    background: #f0f3f7;
    box-sizing: border-box;
    border: 1px solid #cfd7df;
    border-radius: 4px;
    margin: 40px 0px 30px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    border-bottom: none;

    p {
      color: @color-dark-gray;
    }
  }
  .linesOfBusinessWrap {
    padding: 10px;
    margin-top: 0;
    justify-content: flex-start;
    height: 88%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .selectedLos {
    background: @color-zircon;
  }
  .noLoBs {
    align-items: center;
    justify-content: center;
  }
  .brandList {
    .branName,
    .lobName {
      margin-left: 20px;
    }
    height: 100%;
    .icon-button,
    .icon-button-veil,
    .icon-button-veil:not([disabled]) {
      height: 35px;
      width: 35px;
    }

    .brandAvatar {
      margin-right: 10px;
    }

    .iconRow,
    .brandName {
      display: flex;
      align-content: center;
      align-items: flex-start;
    }
    .lobName {
      display: flex;
      flex-direction: column;
    }

    .hm-row {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .logo-img {
        width: 160px;
        align-items: center;
        display: flex;
        flex-grow: 0 !important;

        svg {
          width: 160px;
        }
      }
    }
  }

  .edit-lob-modal-container {
    > div {
      flex: 1;
    }

    display: flex;
    text-align: left;

    .edit-avatar-container {
      display: flex;
      align-items: center;

      .edit-avatar-label {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 12px;
      }
    }

    .type-container {
      text-align: left;
      line-height: 10px;
      margin-top: 32px;
      text-transform: capitalize;
    }

    .lob-icon-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      margin-left: 12px;
    }

    .lob-icon-container {
      display: flex;
      margin: 15px 0;
    }

    .text-input-line {
      width: 100%;
    }
  }

  .remove-lob-modal-container {
    #remove-lob-intro p {
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.5px;
      text-align: left;
      color: black;
      margin-bottom: 30px;
      span {
        font-size: 19px;
        font-weight: 600;
      }
    }

    .lob-grey-container {
      background: #f0f3f7;
      border-radius: 4px;
      display: flex;
      justify-items: center;
      align-items: center;
      height: 80px;
      padding: 24px;
      margin-bottom: 30px;

      > div {
        display: flex;
        width: -webkit-fill-available;
        align-items: center;
      }

      .edit-avatar-label {
        font-size: 18px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;
      }

      .lob-icon-title,
      label {
        margin-left: 20px;
        margin-bottom: 0;
      }
    }
  }
}
