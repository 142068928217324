@import '../../../../styles/variables.less';

.pinnedChatsSection {
  background-color: white;
  :global(.collapsible-section-header) {
    color: @grey;
    font-size: @font-size-medium-rem;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
  }

  :global(.collapsible-section-header-open) {
    border-bottom: 1px solid #cfd7df !important;
  }
  :global(.collapsible-section-icon) {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: inline-flex;
  }

  :global(.dropdown-icon) {
    stroke: @grey;
    stroke-width: 2;
  }
  :global(.label-and-icon-container) {
    display: flex;
    align-items: center;
    :global(.collapsible-section-icon) {
      width: 20px;
      height: 20px;

      path {
        fill: @grey;
      }
    }
  }
}

.chatScrollableContainer {
  box-shadow: 0px 0px 2px @color-gray;
}


.inboxUnpinnedHeader {
  height: 53px;
  padding: 16px;
  color: @grey;
  font-size: @font-size-medium-rem;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  background: white;
}
.unreadLeftBar {
  border-left: 6px solid @shade-of-reddish!important;
}

.whiteLeftBorder {
  border-left: 6px solid #ffffff !important;
}
.chatCardContainer {
  display: flex;
  flex-direction: row;
  p {
    margin-bottom: 12px;
  }
}

.chatDetailsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.teaser {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: @customerName;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.inboxCard,
.activeChatCard {
  border-radius: 0 !important;
  border-top: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
  padding: 16px 16px 16px 10px !important;
  box-shadow: none !important;
}

.unreadCard {
  padding-left: 9px !important;
}

.activeChatCard {
  background-color: @color-aqua-haze !important;
  border-top: 1px solid @grey !important;
  border-right: 1px solid @grey !important;
  border-bottom: 1px solid @grey !important;
  border-left: none !important;
  padding-left: 16px !important;
}

.customerName {
  color: @customerName;
  font-size: @font-size-normal-rem !important;
  font-weight: @font-weight-medium;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.greyText {
  color: @grey;
  font-size: 12px;
}

.messageIndicationContainer {
  display: flex;
  justify-content: center;
  margin-right: 12px;
}

.unreadMessagesCount {
  width: 8px !important;
  height: 8px !important;
  padding: 0px !important;
  margin-right: 5px !important;
  margin-top: 3px !important;
  margin-bottom: 0px !important;
  margin-left: 5px !important;
}

.caseNumber {
  text-wrap: nowrap;
}

.messageIndicationContainer svg {
  width: 18px;
  height: 18px;
}

.ellipsesContainer {
  width: 24px;
  height: 24px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;

  svg {
    fill: @grey;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    font-size: 10px;
    margin: 2px 4px;
  }

  :global .openDropdownMenu {
    background: #f2f3f7 !important;
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }
}

.DropdownMenuSubContent {
  width: 170px !important;
  left: -18px !important;
  position: fixed;
  > * {
    background-color: white !important;
  }
  span {
    font-weight: 400;
  }
}

@media only screen and (max-width: @mobile-width) {
  .DropdownMenuSubContent {
    left: -150px !important;
  }
}

.alignRight {
  text-align: right;
}

.inboxDetailsContainer {
  width: 100%;
}

.column {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.caseNumberRow {
  height: 24px;
  p {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.extraPaddingRow {
  margin-bottom: 12px;
}

.tagsRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.tagItemsContainer {
  width: 100%;
  max-width: 270px;
}

.pinnedLabel {
  display: flex;
  align-items: center;
  padding-left: 16px;
  color: @color-fiord;
  font-size: @font-size-medium-rem;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  background-color: @color-catskill-white;
  border-bottom: 1px solid @color-gray-background;
  border-left: 0;
  border-right: 0;
  border-top: 0;
}

.pinnedRow {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  svg {
    margin-right: 5px;
    path {
      fill: @color-fiord;
    }
  }

  .pinnedColumn {
    display: flex;
  }
}

.caretDown {
  svg {
    transform: rotate(180deg);
  }
}

// TO DO: redo as part of HMB-6203
.selectedCard {
  background-color: rgba(59, 199, 235, 0.1) !important;
}

.caseNumberTooltip {
  max-width: fit-content !important;
  user-select: text !important;
  font-size: @font-size-medium-rem !important;
  padding: 8px 12px !important;
}

.list {
  background-color: @color-athens-gray;
}

.pinnedBadge {
  font-size: @font-size-medium-rem !important;
}
.hiddenCard {
  background: linear-gradient(
      0deg,
      @color-catskill-white 0%,
      @color-catskill-white 100%
    ),
    @color-white !important;
  &:hover {
    cursor: not-allowed;
  }
  .tagsRow {
    height: 24px;
  }
}

.hiddenCaseTooltip {
  &:hover {
    cursor: not-allowed;
  }
}

.corruptCard {
  background-color: @color-chablis !important;
  border-left-color: @color-chablis !important;
}

.missingInfo {
  color: @color-red-robbin;
}

.animateFadeOut {
  height: 0;
  width: 0;
  opacity: 0;
}

.animateFadeIn {
  opacity: 1;
}

.expandedRow {
  display: flex;
  flex-direction: column;
}

.compactRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tagsActionRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

::view-transition-group(*) {
  animation-timing-function: ease-in-out;
  animation-duration: 0.3s;
}

.subIconContainer {
  .svg {
    height: 16px;
    width: 16px;
  }
}
