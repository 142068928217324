@import '../../../../styles/variables.less';

.marley-dropdown {
    font-size: 14px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    position: relative;
    color: #1F3042;
    cursor: pointer;

    .marley-dropdown-link {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
    
    .marley-dropdown-container {
      position: 'relative';
  
      .marley-dropdown-container-inner {
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        position: absolute;
        padding: 8px;
        width: 210px;
        z-index: 100;
      }

      .rightPosition {
        right: 0;
      }
      .leftPosition {
        left: 0;
      }
    }
  
    .marley-dropdown-caret {    
      &.marley-dropdown-open {
        svg {
          transform: rotate(180deg);
          width: 12px;
          height: 9px;
        }
      }
      &.marley-dropdown-closed {
        svg {
          width: 12px;
          height: 9px;
        }
      }
    }

  }
  .case-form-item {
    .dropdown-disabled {
      pointer-events: none;
      border-bottom: none;
      .marley-dropdown-caret {
        display: none;
      }
    }
  }
  
  .no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  