@import '../../styles/variables.less';

.search-bar {
  margin-right: 10px;
}

.dropdown-toggle {
  box-shadow: none !important;
}

.claims-page-modal,
.policies-page-modal {
  .assign-user-dropdown {
    width: 100%;
    .dropdown {
      width: 100%;
      .assign-user-dropdown-button {
        width: 100%;
      }
    }
  }
}

.assign-user-dropdown {
  .panel-body {
    padding: 8px 0px;
  }

  li {
    padding: 0;
    height: 32px;
    border: 0;
    font-size: @font-size-normal;
    line-height: 26px;
    color: @color-black;

    a {
      height: 32px;
      width: 100%;
      padding-top: 0px;
      padding-bottom: 0px;
      display: flex;
      align-items: center;
    }
  }

  .assign-user-dropdown-button {
    width: 162px;

    .assign-user-dropdown-button-selected {
      flex: 1;
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
      /* width: 120px; */
      overflow: hidden;
    }
  }

  .dropdown .search-bar {
    margin-right: 13px;

    .filter-input {
      width: 100%
    }
  }

  .dropdown-menu {
    right: 0 !important;
    left: auto;

    &:focus {
      outline: 0;
    }
  }

  div:focus {
    outline: 0;
  }

  .enter-claims-assign-dropdown, .enter-form-primary-contact-dropdown {

    .form-control:focus {
      border-radius: 8px;
    }
  }

  .dropdown-menu, .enter-claims-assign-dropdown .dropdown-menu{
    min-height: 137px;
    width: 650px;

    .select-list-item-info {
      background-color: @color-gray-background;
    }

    .select-list-item-selected {
      .glyphicon {
        width: 15px;
        display: block !important;
      }
    }

    .select-list-item {
      padding: 0;
      height: 32px;
      border: 0;
      font-size: 14px;
      font-family: @font-family-table;
      line-height: 26px;
      color: @color-black;

      .assign-user-item {
        padding: 0px 8px;
        display: flex;
        width: 100%;

        span {
          flex: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
          white-space: nowrap;
          cursor: pointer;
        }

        .glyphicon {
          display: none;
          text-align: right;
          line-height: unset;
          flex:none;
        }
      }
    }
  }
}
