@import "../../../../styles/variables";

.general-auto-replies-container {
  margin-top: 4%;
  padding-left: 5%;
  padding-right: 5%;
  height: auto;
  .general-auto-replies-header {
    display: flex;
    flex-direction: row;
    margin-top: 1%;
    width: 100%;
    height: 15%;
    align-items: center;
    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      margin-top: 15px;
    }
    .button-row {
      margin-left: auto;
      .add-general-auto-replies-message {
        font-size: 13px;
        line-height: 14px;
        display: flex;
        text-align: right;
        background: white;
        border: none;
        color: #5e7388;
      }
    }
  }
  .general-auto-replies-subheader {
    p {
      font-weight: normal;
      font-size: 15px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #1f3042;
    }
  }

}
