@import "../../../styles/variables";

.hymarley-button{
  height: 34px;
  min-width: 128px;
  color: @color-white;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border: 1px solid transparent;
  border-radius: 3px;
  box-sizing: border-box;
  &:focus:active {
    border: 0 !important;
  }
  &.primary{
    background-color: @color-ui-green;
    &:hover:enabled{
      background-color: @color-marley-green-lighter20;
    }
    &:active:enabled{
      background-color: @color-marley-green-darker30;
    }
    &:focus:active {
      border: 0 ;
    }
  }
  &.whiteRounded {
    background-color: @color-white;
    border: 1px solid @color-dark-slate-blue;
    border-radius: 16px;
    color: @color-dark-slate-blue;
  }
  &.darkBlueRounded {
    background-color: @color-dark-slate-blue;
    color: @color-white;
    border-radius: 16px;
  }
  &.black {
    background-color: @color-darkest-gray;
  }
  &.link{
    background-color: transparent;
    color: @color-black;
  }
  &.outline{
    background-color: @color-white;
    border: 1px solid @color-button-secondary;
    color: @color-button-secondary;
    &:hover:enabled{
      background-color: @color-button-secondary-lighter20;
      color: @color-white;
    }
    &:active:enabled{
      background-color: @color-button-secondary-darker30;
      color: @color-white;
    }
  }
  &.secondary{
    background-color: @color-button-secondary;
    &:hover:enabled{
      background-color: @color-button-secondary-lighter20;
    }
    &:active:enabled{
      background-color: @color-button-secondary-darker30;
    }
  }
  &.red{
    background-color: @color-marley-red;
    &:hover:enabled{
      background-color: @color-marley-red-lighter20;
    }
    &:active:enabled{
      background-color: @color-marley-red-darker30;
    }
  }
  &.blue{
    background-color: @color-marley-blue;
  }
  &.error{
    background-color: @color-marley-red;
  }
  &.pending{
    background-color: @color-pending-button;
  }
  &.default{
    background-color: @color-white;
    box-shadow: 0 0 4px 0 @color-button-secondary;
    color: @color-button-secondary;
  }
  &:disabled{
    background-color: @color-gray-for-disabled-dropdown;
    color: @color-gray;
    cursor: default;
  }
  &:focus:enabled{
    border-radius: 3px;
    border: 1px solid @color-lightest-blue;
  }
}
