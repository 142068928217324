

@import '../../../../../styles/variables';

.emoji-panel-button, .emoji-panel-button-active{
    border: 1px solid transparent;
    padding: 0;
    background-color: transparent;
    .emoji{
        padding: 4px;
        font-size: 22px;
        width: 100%;
        margin: 1px;  
        cursor: pointer;
        border: 1px solid transparent;
    }
    &:hover{
        border: 1px solid @color-lightest-blue;	
        border-radius: 2px;	
        background-color: rgba(59,199,235,0.1);
    }
}

.emoji-panel{
    .marley-modal {
        height: 171px;

        .marley-modal-body {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    
        .emoji-panel-header {
            display: flex;
            background-color: #fff;
            padding-bottom:5px;

            .emoji-panel-help-left, .emoji-panel-help {
                font-size: 9px;
                color: @color-button-secondary;	
            }
            .emoji-panel-help-left{
                margin-right: auto;
            }
            .emoji-panel-help{
                margin-left: 8px;
            }
        }

        .emoji-icon-container {
            flex: 1;
            overflow-y: auto;
        }
    }
    .panel-body{
        display: flex;
        flex-wrap: wrap;
        max-height: 137px;
        overflow-y: auto;
        padding: 3px;
        justify-content: center;
    }

    .emoji-panel-button-active{
        border: 1px solid @color-lightest-blue;	
        border-radius: 2px;	
        background-color: rgba(59,199,235,0.1);
    }
}