@import '../../../styles/variables';

.mass-notification-modal,
.mass-notification-view-modal {
  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }

  .mass-notification-warning.first,
  .mass-notification-warning.alone {
    margin-bottom: 15px;
  }

  .mass-notification-warning:not(.first) {
    text-align: center;
  }

  .mass-notification-warning {
    margin-bottom: 30px;
  }
}

.mass-note-verification-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}