@import '../../../styles/variables';

.profilePage {
  padding-left: 24px;
  .title {
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */
    font-weight: bolder;
    color: #000000;
  }

  .description {
    /* This is where you customize all e-mail related configurations, including notifications */
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    margin-top: 16px;
    color: #000000;
  }
}
