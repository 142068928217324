
.large-look-content-container {
  display: flex;
  flex: 1;
  justify-content: center;
  min-width: 100%;
  .large-look-iframe {
    display: flex;
    flex: 1;
    padding-right: 83px;
    padding-left: 83px;
    padding-bottom: 83px;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
