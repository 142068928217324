@import "../../styles/variables";

.app-header {
  background: @color-white;
  -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, .1);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, .1);
  z-index: 14;

  .mobile-friendly-header { 
    display: flex; 
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    height: 80px;
    button {
      padding: 0;
    }
    .profile-page-back-button,
    .create-case-button,
    .right-header-icon-placeholder {
      background-color: @color-white;
      color: @color-dark-gray;
      height: 40px;
      width: 40px;
      svg {
        color: @color-dark-gray;
      }
    }
    .user-photo2 {
      background-color: white;
    }
  }

  .app-header-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .header-left {
      display: flex;
      flex-direction: row;
      justify-content: left;
      height: @header-height;
      align-items: center;
      color: @color-dark-gray;

      .icon-box {
        width: 140px;
        display: flex;
        justify-content: center;
        overflow: hidden;
      }

      p {
        margin: 0 10px;
        font-size: @font-size-rem;
        line-height: @header-height;
      }

      .label-pro {
        background-color: @color-lighter-grey;
        border-radius: 20px;
        padding: 2px 10px;
        margin-right: 20px;
        color: @color-fiord;
      }
    }

    .status-menu {
      left: -210px;
      position: absolute;
    }

    .minus-icon {
      color: white;
      width: 6px;
      height: 15px;
      line-height: 16px;
      font-size: 16px;
    }

    .menu-caret {
      position: absolute;
      right: 16px;
      top: 73px;
      font-size: 18px;
      font-weight: 300;
    }

    .header-profile {
      padding-right: 16px;
      display: flex;
      align-items: center;

      .list-item {
        // Fixing header dropdown differences
        justify-content: unset;

        .list-item-icon {
          margin-right: 5px;
        }

        .list-item-check {
          display: none;
        }
      }

      .user-auto-assign-block {
        margin-right: 25px;
        display: flex;
        height: 100%;
        align-items: center;

        .auto-assign {
          display: flex;
          margin-right: 20px;

          div {
            margin-left: 5px;
            line-height: 14px;
          }
        }
      }

      .mute-button.btn:focus.btn:active:focus {
        outline: none;
      }

      .dropdown-profile {
        display: flex;
        padding: 8px 0px 16px 8px;
        overflow: hidden;

        .dropdown-profile-name-score {
          padding-left: 10px;
          align-items: flex-start;
          flex-direction: column;
          justify-content: center;
          display: flex;
        }

        .dropdown-profile-user-name {
          font-size: 12px;
          font-weight: 600;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 132px;

          &.dropdown-profile-user-name-larger {
            font-size: 14px;
            display: flex;
            align-items: center;
            white-space: unset;
            overflow: hidden;
          }
        }
      }

      .user-name-block {
        display: flex;
        align-items: center;
        height: 60px;

        .home-icon-container {
          width: 19px;
          height: 19px;

          svg {
            width: 19px;
            height: 19px;
          }

          margin-right: 24px;
        }

        .score-display {
          align-items: center;
        }

        .header-button {
          padding: 0px 8px;
        }
      }
    }
  }
}
