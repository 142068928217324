@import "../../../../../styles/variables.less";

.message-buttons {
  .left-message-button {
    margin-right: 24px;
  }
}

.undo {
  display: flex;
  width: inherit;
  margin-top: 6px;
  .undo-message {
    height: 17px;
    color: #16212E;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 17px;
    padding-right: 10px;
  }
  .undo-button {
    height: 17px;
    width: 72px;
    color: #3BC7EB;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 17px;
    justify-content: left;
  }
  .undo-button:hover {
    cursor: pointer;
  }
}

.back-button {
  transform: rotateY(180deg) rotate(35deg) scale(0.5);
}
