@import '../../../../styles/variables';

.cardDensityWrap {
  display: flex;
  align-items: center;
  column-gap: 8px;
  view-transition-name: card-density-control;
  button {
    padding: 0;
  }
}

.selected {
  rect {
    fill: $color-dark-gray;
    stroke: $color-dark-gray;
  }
}

.unselected {
  rect {
    fill: #FFFFFF;
    stroke: $color-gray-chateau;
  }
}