@import "../../../styles/variables";

.customer-feedback-wrap {
  display: flex;
  flex-direction: column;
  flex: 100;
  padding: 10px;
  height: 100%;

  .customer-feedback-title {
    color: @color-black;
    font-size: 20px;
    font-weight: 600;
    line-height: 19px;
    margin: 24px 0px 12px 14px;
  }

  .customer-feedback-rate-wrap {
    margin: 21px 14px;

    .customer-feedback-rate-stars {
      color: #FFE92A;
      overflow: hidden;
      display: flex;
      margin-left: 1px;
    }

    .customer-feedback-rate-num {
      color: @color-lightest-blue;
      font-family: @font-family-table;
      font-size: 48px;
      font-weight: 600;
      line-height: 63px;
      letter-spacing: -5px;
    }
  }

  .customer-feedback-list {
    overflow: auto;
  }

  .customer-feedback-empty {
    margin: 70px 56px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .customer-feedback-empty-icon {
      height: 36px;
      width: 39px;
      margin-bottom: 18px;
    }

    .customer-feedback-empty-header {
      color: @color-gray;
      font-weight: 600;
      font-size: @font-size-biggest;
      line-height: 33px;
      text-align: center;
    }

    .customer-feedback-empty-help {
      color: @color-gray;
      font-size: @font-size-big;
      line-height: 17px;
      margin-top: 16px;
      text-align: center;
    }
  }
}