@import '../../../styles/variables.less';

.mass-notification-view-modal {
    .textDisplay {
        border: 1px solid #CFD7DF;
        width: 88%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        height: auto;
        padding: 15px;
    }

    .boldFormattedWord {
        font-weight: 600;
    }

    .inputTitle {
        margin-bottom: 4px;
    }

    .mass-notification-secondary-title {
        height: unset;
        width: 100%;
        margin-bottom: 5px;
    }
}