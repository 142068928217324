@import '../../../../styles/variables.less';

.user-profile {
  display: flex;
}

.user-profile-avatar {
  align-items: center;
}

.user-profile-info {
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  width: 100%;
}

.user-profile-name {
  font-weight: 600;
}

.user-photo2.user-profile-circle {
  border-radius: 100%;
  overflow: hidden;
  width: 40px;
  height: 40px;
}